import React, { useState, useEffect, Fragment } from "react";
import TimeInput from "material-ui-time-picker";
import OutlinedDiv from "../ui/OutlinedDiv";
import CustomSelect from "../ui/CustomSelect";
import Creatable from "react-select/creatable";
import makeAnimated from "react-select/animated";
import {
  toHoursAndMinutes,
  getTime,
  secondsToDateObject,
  formatDate,
  setServiceTime,
} from "../../helpers";
import { equipmentOptions } from "../../options";
import { customStyles } from "../../styles";
import {
  InputLabel,
  ButtonGroup,
  Button,
  Alert,
  AlertTitle,
  Grid,
  FormLabel,
  FormGroup,
  FormControlLabel,
  RadioGroup,
  Radio,
  Input,
} from "@mui/material";
import { Switch } from "react-switch-input";
import axios from "axios";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import ModalDialog from "../ui/ModalDialog";

const animatedComponents = makeAnimated();
const token = document.querySelector('[name="csrf-token"]').content;

axios.defaults.headers.common["X-CSRF-Token"] = token;
alertify.set("notifier", "position", "top-right");
alertify.set("notifier", "delay", 5);

const ServiceForm = ({
  showForm,
  onClick,
  flight,
  service,
  setService,
  onTypeSelect,
  locale,
}) => {
  const [fieldOptions, setFieldOptions] = useState([]);
  const [supervisors, setSupervisors] = useState([]);
  const [arrival, setArrival] = useState(false);
  const [turnaround, setTurnaround] = useState(false);
  const [showThirdOption, setShowThirdOption] = useState(true);
  const [departure, setDeparture] = useState(false);
  const [csrRequired, setCsrRequired] = useState(false);
  const [securityCabinSearch, setSecurityCabinSearch] = useState(false);
  const [passengerService, setPassengerService] = useState(false);
  const [rampService, setRampService] = useState(true);
  const [timeFrom, setTimeFrom] = useState(new Date());
  const [timeTo, setTimeTo] = useState(new Date());
  const [timedFrom, setTimedFrom] = useState(new Date());
  const [timedTo, setTimedTo] = useState(new Date());
  const [color, setColor] = useState("#00C851");
  const [crews, setCrews] = useState([]);
  const [supervisor, setSupervisor] = useState([]);
  const [equipment, setEquipment] = useState([]);
  const [flightType, setFlightType] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [errors, setErrors] = useState({
    supervisor: { text: "", isValid: true },
    crews: { text: "", isValid: true },
  });
  const isNewService = service.id === undefined;
  const initialState = () => {
    const timeObj = setServiceTime(flight, flightType);
    setTimeFrom(arrival ? new Date(timeObj.start) : new Date());
    setTimeTo(arrival ? new Date(timeObj.end) : new Date());
    setTimedFrom(departure ? new Date(timeObj.start) : new Date());
    setTimedTo(departure ? new Date(timeObj.end) : new Date());
    setColor("#00C851");
    setCrews([]);
    setSupervisor([]);
    setEquipment([]);
  };

  useEffect(() => {
    fetch(
      `/admin/services/find_services?flight_date=${formatDate(
        flight.date_a
      )}&flight_date2=${formatDate(flight.date_d)}&flight_in=${
        flight.in
      }&flight_out=${flight.out}&flight_id=${flight.id}`
    )
      .then((response) => response.json())
      .then((json) => {
        setShowThirdOption(!json.found_services);
      });

    fetch("/admin/crews.json")
      .then((response) => response.json())
      .then((json) => {
        setFieldOptions(
          json.map((el) => {
            return {
              value: el.id,
              label: `${el.code} / ${el.crew_type}`,
            };
          })
        );
      });

    fetch(
      `/admin/schedules.json?q[date_gteq]=${flight.date}&q[date_lteq]=${flight.date}`
    )
      .then((response) => response.json())
      .then((json) => {
        setSupervisors(
          json
            .sort((a, b) => {
              return a.time_from > b.time_from;
            })
            .map((el) => {
              let convertedTime = `${el.name} (${toHoursAndMinutes(
                el.time_from
              )} - ${toHoursAndMinutes(el.time_to)})`;
              return { label: convertedTime, value: el.name };
            })
        );
      });
  }, []);

  useEffect(() => {
    if (!isNewService) {
      setTimeFrom(secondsToDateObject(service.time_from));
      setTimeTo(secondsToDateObject(service.time_to));
      setTimedFrom(secondsToDateObject(service.time_dfrom));
      setTimedTo(secondsToDateObject(service.time_dto));
      setSupervisor(service.supervisor);
      setCrews(service.crews);
      setEquipment(service.equipment);
      setColor(service.color);
      setCsrRequired(service.csr_required);
      setPassengerService(service.passenger_service);
      setSecurityCabinSearch(service.security_cabin_search);
      setRampService(service.ramp_service);
    } else {
      initialState();
    }
  }, [service, isNewService]);

  const closeModalHadler = () => {
    setService({});
    onClick(false);
    setFlightType("");
    customStyles.content.height = "";
  };

  const onTimeFromChange = (time) => {
    setTimeFrom(time);
  };

  const onTimeToChange = (time) => {
    setTimeTo(time);
  };

  const onTimedFromChange = (time) => {
    setTimedFrom(time);
  };

  const onTimedToChange = (time) => {
    setTimedTo(time);
  };

  const onCrewsSelectHandler = (option) => {
    setCrews(option);
    setErrors((prevState) => ({
      ...prevState,
      crews: { text: "", isValid: true },
    }));
  };

  const onSupervisorSelectHandler = (option) => {
    setSupervisor(option);
    setErrors((prevState) => ({
      ...prevState,
      supervisor: { text: "", isValid: true },
    }));
  };

  const handleToggleChange = (e) => {
    const { name, checked } = e.target;

    if (name === "csr_required") {
      setCsrRequired(checked);
    } else if (name === "security_cabin_search") {
      setSecurityCabinSearch(checked);
    } else if (name === "passenger_service") {
      setPassengerService(checked);
    } else if (name === "ramp_service") {
      setRampService(checked);
    }
  };

  const handleFlightTypeChange = (e) => {
    const { value } = e.target;

    if (
      (value === "arrival" && flight.in !== "-" && flight.date_a !== "-") ||
      (value === "departure" && flight.out !== "-" && flight.date_d !== "-") ||
      (value === "turnaround" &&
        flight.in !== "-" &&
        flight.out !== "-" &&
        flight.date_a !== "-" &&
        flight.date_d !== "-")
    ) {
      setFlightType(value);
      customStyles.content.height = "85vh";
      setArrival(value === "arrival");
      setDeparture(value === "departure");
      setTurnaround(value === "turnaround");
      onTypeSelect(flight, value);
    } else {
      alertify.error(locale("error_messages.missing_data"));
    }
  };

  const onEquipmentSelectHandler = (arrOpt) => {
    setEquipment(arrOpt);
  };

  const onFormSubmitHandler = () => {
    const regExp = /[a-zA-Z]/g;
    const dataArr = [];
    let data = {
      service: {
        // Flight details
        id: service.id,
        flight_aware_id: arrival
          ? flight.inbound_fa_flight_id
          : flight.outbound_flight_id,
        flight_number: arrival ? flight.reg1 : flight.reg2,
        flight_id: regExp.test(flight.id.toString()) ? null : flight.id,
        registry_line_id: arrival ? flight.line_id1 : flight.line_id2,
        flight_date:
          flight.date_a && arrival ? formatDate(flight.date_a) : null,
        flight_in: arrival ? flight.in : "-",
        flight_from: arrival ? flight.from : "-",
        flight_sta: arrival ? flight.sta : "-",
        flight_eta: arrival ? flight.eta : "-",
        flight_ata: arrival ? flight.ata : "-",
        flight_status: arrival ? flight.flight_status : "-",
        aircraft_type: arrival ? flight.aircraft_type : "-",
        flight_gate: arrival ? flight.gate : "-",
        arrival_delay: arrival ? flight.arrival_delay : false,
        time_from: arrival ? getTime(timeFrom) : 0,
        time_to: arrival ? getTime(timeTo) : 0,

        flight_date2:
          flight.date_d && departure ? formatDate(flight.date_d) : null,
        flight_out: departure ? flight.out : "-",
        flight_dest: departure ? flight.dest : "-",
        flight_std: departure ? flight.std : "-",
        flight_etd: departure ? flight.etd : "-",
        flight_atd: departure ? flight.atd : "-",
        flight_dstatus: departure ? flight.flight_dstatus : "-",
        aircraft_dtype: departure ? flight.aircraft_dtype : "-",
        flight_dgate: departure ? flight.dgate : "-",
        departure_delay: departure ? flight.departure_delay : false,
        time_dfrom: departure ? getTime(timedFrom) : 0,
        time_dto: departure ? getTime(timedTo) : 0,

        // Service details
        progress_percent: arrival
          ? flight.progress_percent1
          : flight.progress_percent2,
        csr_required: csrRequired,
        security_cabin_search: securityCabinSearch,
        passenger_service: passengerService,
        ramp_service: rampService,
        arrival: arrival,
        departure: departure,
        supervisor: supervisor,
        crews: crews,
        equipment: equipment,
        color: color,
      },
    };

    if (turnaround) {
      ["arrival", "departure"].forEach((type) => {
        const isArrival = type === "arrival";
        const isDeparture = type === "departure";
        Date.prototype.addHours = function (h) {
          this.setHours(this.getHours() + h);
          return this;
        };
        Date.prototype.subtractHours = function (h) {
          this.setHours(this.getHours() - h);
          return this;
        };

        dataArr.push({
          service: {
            // Flight details
            id: service.id,
            flight_number: isArrival ? flight.reg1 : flight.reg2,
            flight_id: regExp.test(flight.id.toString()) ? null : flight.id,
            registry_line_id: isArrival ? flight.line_id1 : flight.line_id2,
            flight_date:
              flight.date_a && isArrival ? formatDate(flight.date_a) : null,
            flight_in: isArrival ? flight.in : "-",
            flight_from: isArrival ? flight.from : "-",
            flight_sta: isArrival ? flight.sta : "-",
            flight_eta: isArrival ? flight.eta : "-",
            flight_ata: isArrival ? flight.ata : "-",
            flight_status: isArrival ? flight.flight_status : "-",
            aircraft_type: isArrival ? flight.aircraft_type : "-",
            flight_gate: isArrival ? flight.gate : "-",
            arrival_delay: isArrival ? flight.arrival_delay : false,
            time_from: isArrival
              ? getTime(new Date(`${flight.date}T${flight.sta}`))
              : 0,
            time_to: isArrival
              ? getTime(new Date(`${flight.date}T${flight.sta}`).addHours(1))
              : 0,

            flight_date2:
              flight.date_d && isDeparture ? formatDate(flight.date_d) : null,
            flight_out: isDeparture ? flight.out : "-",
            flight_dest: isDeparture ? flight.dest : "-",
            flight_std: isDeparture ? flight.std : "-",
            flight_etd: isDeparture ? flight.etd : "-",
            flight_atd: isDeparture ? flight.atd : "-",
            flight_dstatus: isDeparture ? flight.flight_dstatus : "-",
            aircraft_dtype: isDeparture ? flight.aircraft_dtype : "-",
            flight_dgate: isDeparture ? flight.dgate : "-",
            departure_delay: isDeparture ? flight.departure_delay : false,
            time_dfrom: isDeparture
              ? getTime(
                  new Date(`${flight.date}T${flight.std}`).subtractHours(1)
                )
              : 0,
            time_dto: isDeparture
              ? getTime(new Date(`${flight.date}T${flight.std}`))
              : 0,

            // Service details
            progress_percent: isArrival
              ? flight.progress_percent1
              : flight.progress_percent2,
            csr_required: csrRequired,
            security_cabin_search: securityCabinSearch,
            passenger_service: passengerService,
            ramp_service: rampService,
            arrival: isArrival,
            departure: isDeparture,
            supervisor: supervisor,
            crews: crews,
            equipment: equipment,
            color: color,
          },
        });
      });
    }

    if (
      data.service.supervisor.length === 0 ||
      data.service.crews.length === 0
    ) {
      if (data.service.supervisor.length === 0) {
        setErrors((prevState) => ({
          ...prevState,
          supervisor: {
            text: locale("error_messages.not_blank"),
            isValid: false,
          },
        }));
      }
      if (data.service.crews.length === 0) {
        setErrors((prevState) => ({
          ...prevState,
          crews: { text: locale("error_messages.not_empty"), isValid: false },
        }));
      }
      setInvalid(true);
    } else {
      const options = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      if (turnaround) {
        const message = locale("success_messages.services_created");
        dataArr.forEach((data, index) => {
          axios.post("/admin/services.json", data, options).catch((error) => {
            console.log(error);
            console.log("************************************");
          });
          if (index === 1) {
            setService({});
            onClick(false);
            customStyles.content.height = "";
            alertify.message(message);
          }
        });
      } else {
        const request = !isNewService
          ? axios.put(`/admin/services/${service.id}.json`, data, options)
          : axios.post("/admin/services.json", data, options);

        const message = isNewService
          ? locale("success_messages.service_created")
          : locale("success_messages.service_updated");

        request
          .then((response) => {
            setService({});
            onClick(false);
            customStyles.content.height = "";
            alertify.message(message);
          })
          .catch((error) => {});
      }
    }
  };

  return (
    <ModalDialog
      showForm={showForm}
      contentLabel={"Service Modal"}
      title={locale("schedule_service")}
      closeClicked={closeModalHadler}
    >
      {invalid && (
        <Alert severity="error" sx={{ marginBottom: "20px" }}>
          <AlertTitle>{locale("error_messages.main1")}</AlertTitle>
        </Alert>
      )}

      <FormGroup>
        <FormLabel id="flight-type">{locale("select_option")}</FormLabel>
        <RadioGroup
          aria-labelledby="flight-type"
          defaultValue=""
          name="radio-buttons-group"
          value={flightType}
          onChange={handleFlightTypeChange}
        >
          <Grid container spacing={2}>
            <Grid item xs={showThirdOption ? 4 : 6}>
              <FormControlLabel
                value="arrival"
                control={<Radio />}
                label={locale("arrival")}
              />
            </Grid>
            <Grid item xs={showThirdOption ? 4 : 6}>
              <FormControlLabel
                value="departure"
                control={<Radio />}
                label={locale("departure")}
              />
            </Grid>
            {showThirdOption && (
              <Grid item xs={4}>
                <FormControlLabel
                  value="turnaround"
                  control={<Radio />}
                  label={locale("turnaround")}
                />
              </Grid>
            )}
          </Grid>
        </RadioGroup>
      </FormGroup>

      {flightType !== "" && (
        <form>
          <InputLabel sx={{ mt: 1, mb: 1 }} htmlFor="flight_date">
            {locale("date")}
          </InputLabel>
          <Input
            placeholder={locale("date")}
            id="flight_date"
            name="flight_date"
            type="date"
            value={flight.date}
            readOnly={true}
            sx={{ width: "100%" }}
          />
          <br />
          {arrival && (
            <Fragment>
              <InputLabel sx={{ mt: 1, mb: 1 }} htmlFor="flight_in">
                {locale("flight_number")}
              </InputLabel>
              <Input
                placeholder={locale("inbound")}
                id="flight_in"
                name="flight_in"
                type="text"
                value={flight.in}
                readOnly={true}
                sx={{ width: "100%" }}
              />
              <br />
            </Fragment>
          )}
          {departure && (
            <Fragment>
              <InputLabel sx={{ mt: 1, mb: 1 }} htmlFor="flight_out">
                {locale("flight_number")}
              </InputLabel>
              <Input
                placeholder={locale("outbound")}
                id="flight_out"
                name="flight_out"
                type="text"
                value={flight.out}
                readOnly={true}
                sx={{ width: "100%" }}
              />
              <br />
            </Fragment>
          )}
          <InputLabel sx={{ mt: 1, mb: 1 }} htmlFor="flight_number">
            {locale("reg_num")}
          </InputLabel>
          <Input
            placeholder={locale("reg_num")}
            id="flight_number"
            name="flight_number"
            type="text"
            value={arrival ? flight.reg1 : flight.reg2}
            readOnly={true}
            sx={{ width: "100%" }}
          />
          <br />
          <div className="space"></div>
          {arrival && (
            <OutlinedDiv label={locale("planned_service_time1")}>
              <InputLabel sx={{ mt: 1, mb: 1 }} htmlFor="time_from">
                {locale("time_from")}
              </InputLabel>
              <TimeInput
                id="time_from"
                name="time_from"
                mode="24h"
                style={{ width: "100%" }}
                value={timeFrom}
                onChange={(time) => {
                  onTimeFromChange(time);
                }}
              />
              <br />
              <InputLabel sx={{ mt: 1, mb: 1 }} htmlFor="time_to">
                {locale("time_to")}
              </InputLabel>
              <TimeInput
                id="time_to"
                name="time_to"
                mode="24h"
                style={{ width: "100%" }}
                value={timeTo}
                onChange={(time) => {
                  onTimeToChange(time);
                }}
              />
            </OutlinedDiv>
          )}
          {departure && (
            <OutlinedDiv label={locale("planned_service_time2")}>
              <InputLabel sx={{ mt: 1, mb: 1 }} htmlFor="time_dfrom">
                {locale("time_from")}
              </InputLabel>
              <TimeInput
                id="time_dfrom"
                name="time_dfrom"
                mode="24h"
                style={{ width: "100%" }}
                value={timedFrom}
                onChange={(time) => {
                  onTimedFromChange(time);
                }}
              />
              <br />
              <InputLabel sx={{ mt: 1, mb: 1 }} htmlFor="time_dto">
                {locale("time_to")}
              </InputLabel>
              <TimeInput
                id="time_dto"
                name="time_dto"
                mode="24h"
                style={{ width: "100%" }}
                value={timedTo}
                onChange={(time) => {
                  onTimedToChange(time);
                }}
              />
            </OutlinedDiv>
          )}
          <div className="space"></div>
          <InputLabel sx={{ marginBottom: "10px" }} htmlFor="supervisor">
            Supervisor
          </InputLabel>
          <CustomSelect
            isMulti
            options={supervisors}
            id="supervisor"
            name="supervisor"
            onChange={onSupervisorSelectHandler}
            value={supervisor}
            isValid={errors.supervisor.isValid}
            errorText={errors.supervisor.text}
          />
          <br />
          <InputLabel sx={{ marginBottom: "10px" }} htmlFor="crews">
            {locale("crew.other")}
          </InputLabel>
          <CustomSelect
            isMulti
            hideSelectedOptions={true}
            options={fieldOptions}
            id="crews"
            name="crews"
            components={animatedComponents}
            onChange={onCrewsSelectHandler}
            value={crews}
            isValid={errors.crews.isValid}
            errorText={errors.crews.text}
          />
          <br />
          <InputLabel sx={{ marginBottom: "10px" }} htmlFor="equipment">
            {locale("equipment")}
          </InputLabel>
          <Creatable
            isMulti
            components={animatedComponents}
            options={equipmentOptions}
            onChange={onEquipmentSelectHandler}
            value={equipment}
          />
          <br />
          <Grid container spacing={1} sx={{ mt: 2, mb: 2 }}>
            <Grid item xs={2}>
              <Switch
                onChange={handleToggleChange}
                name="ramp_service"
                checked={rampService}
                id="ramp_service"
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel sx={{ marginBottom: "10px" }} htmlFor="ramp_service">
                {locale("ramp_service")}
              </InputLabel>
            </Grid>
          </Grid>
          {arrival && (
            <Fragment>
              <Grid container spacing={1} sx={{ mt: 2, mb: 2 }}>
                <Grid item xs={2}>
                  <Switch
                    onChange={handleToggleChange}
                    name="csr_required"
                    checked={csrRequired}
                    id="csr_required"
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel
                    sx={{ marginBottom: "10px" }}
                    htmlFor="csr_required"
                  >
                    {locale("cabin_service_report")}
                  </InputLabel>
                </Grid>
              </Grid>
              <Grid container spacing={1} sx={{ mt: 2, mb: 2 }}>
                <Grid item xs={2}>
                  <Switch
                    onChange={handleToggleChange}
                    name="security_cabin_search"
                    checked={securityCabinSearch}
                    id="security_cabin_search"
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel
                    sx={{ marginBottom: "10px" }}
                    htmlFor="security_cabin_search"
                  >
                    {locale("security_cabin_search")}
                  </InputLabel>
                </Grid>
              </Grid>
            </Fragment>
          )}
          <Grid container spacing={1} sx={{ mt: 2, mb: 2 }}>
            <Grid item xs={2}>
              <Switch
                onChange={handleToggleChange}
                name="passenger_service"
                checked={passengerService}
                id="passenger_service"
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel
                sx={{ marginBottom: "10px" }}
                htmlFor="passenger_service"
              >
                {locale("passenger_service")}
              </InputLabel>
            </Grid>
          </Grid>
          <InputLabel sx={{ marginBottom: "10px" }} htmlFor="color">
            Color
          </InputLabel>
          <input
            type="color"
            value={color}
            onChange={(e) => setColor(e.target.value)}
          />
          <br />
          <br />
          <ButtonGroup
            variant="text"
            aria-label="button group"
            fullWidth={true}
          >
            <Button color="primary" onClick={onFormSubmitHandler}>
              {isNewService ? locale("create") : locale("update")}
            </Button>
            <Button onClick={closeModalHadler} className="cancel" color="error">
              {locale("close")}
            </Button>
          </ButtonGroup>
          <br />
        </form>
      )}
    </ModalDialog>
  );
};

export default ServiceForm;
