import React, { Fragment, useEffect, useState } from "react";
import CustomSelect from "./ui/CustomSelect";
import CreatableSelect from "react-select/creatable";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import LockClockIcon from "@mui/icons-material/LockClock";
import AdminContractService from "./ui/AdminContractService";
import UpdateIcon from "@mui/icons-material/Update";
import AddIcon from "@mui/icons-material/Add";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { blue, green } from "@mui/material/colors";
import { validateService } from "../helpers";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import axios from "axios";
import {
  Alert,
  AlertTitle,
  InputLabel,
  Grid,
  FormGroup,
  FormControlLabel,
  RadioGroup,
  Checkbox,
  Radio,
  IconButton,
  ButtonGroup,
  Button,
  Input,
  FormHelperText,
} from "@mui/material";
import { useTranslation, I18nextProvider } from "react-i18next";
import i18n from "../i18n";

const theme = createTheme({
  palette: {
    primary: {
      main: blue[500],
    },
    secondary: {
      main: "#00e676",
    },
    success: {
      main: green[600],
    },
  },
});

const token = document.querySelector('[name="csrf-token"]').content;
axios.defaults.headers.common["X-CSRF-Token"] = token;
alertify.set("notifier", "position", "top-right");
alertify.set("notifier", "delay", 3);

const onlyUnique = (value, index, array) => {
  return array.indexOf(value) === index;
};

const initialServiceErrors = {
  trl_date: { hasError: false, helperText: "" },
  flight_number: { hasError: false, helperText: "" },
  airline: { hasError: false, helperText: "" },
  aircraft_type: { hasError: false, helperText: "" },
  note: { hasError: false, helperText: "" },
  tail_number: { hasError: false, helperText: "" },
  attention_of: { hasError: false, helperText: "" },
  email: { hasError: false, helperText: "" },
};

const TRLForm = ({
  supervisors,
  ramp_controls,
  airlines,
  trl_form,
  aircrafts,
  mostly_used,
  other_services,
  cs_codes,
  contract_services,
  flight_numbers,
  admin,
  customers,
}) => {
  const [t] = useTranslation();
  const [service, setService] = useState(trl_form);
  const [serviceErrors, setServiceErrors] = useState(initialServiceErrors);

  const [extraServices, setExtraServices] = useState(
    cs_codes.filter(onlyUnique)
  );

  const [errors, setErrors] = useState({});

  const [sameTime, setSameTime] = useState({});

  const [contractServices, setContractServices] = useState({});
  const [flights, setFlights] = useState(flight_numbers);
  const [showAlertMessage, setShowAlertMessage] = useState(false);

  const servicesArr = [...mostly_used, ...other_services];

  useEffect(() => {
    i18n.changeLanguage(admin.language);
    let keys = Object.keys(contract_services);
    for (let i in keys) {
      contract_services[keys[i]].map((item) => {
        item["_destroy"] = "0";
        return item;
      });
    }
    setSameTime(
      servicesArr.reduce((accumulator, currentValue) => {
        accumulator[currentValue.code] = false;
        return { ...accumulator };
      }, {})
    );
    setContractServices(contract_services);
    setService((prevState) => {
      let newState = { ...prevState };
      [
        "trl_date",
        "flight_number",
        "airline",
        "aircraft_type",
        "tail_number",
        "attention_of",
        "supervisor",
        "ramp_control",
        "note",
        "email",
      ].forEach((field) => {
        if (newState[field] === null || newState[field] === undefined) {
          newState[field] = "";
        }
      });
      newState.admin_user_id =
        newState.admin_user_id === null || newState.admin_user_id === undefined
          ? admin.id
          : newState.admin_user_id;
      return newState;
    });
  }, []);

  const changeCustomerTypeHandler = (e) => {
    const { value } = e.target;
    setService((prevState) => {
      return { ...prevState, customer_type: value };
    });
    if (value === "existing") {
      setService((prevState) => {
        return { ...prevState, attention_of: "" };
      });
      setServiceErrors((prevState) => {
        let newState = { ...prevState };
        newState["attention_of"].hasError = false;
        newState["attention_of"].helperText = "";
        return newState;
      });
    }
  };

  const validateEmpty = (fieldName, value) => {
    setServiceErrors((prevState) => {
      let newState = { ...prevState };
      if (value === "" || value === undefined || value === null) {
        newState[fieldName].hasError = true;
        newState[fieldName].helperText = t("required");
      } else {
        newState[fieldName].hasError = false;
        newState[fieldName].helperText = "";
      }
      return newState;
    });
  };

  const validateFlightNumber = (value) => {
    const regex = /^[a-zA-Z]{2,3}\d{2,4}$/;
    const validationResult = value.match(regex)
      ? { hasError: false, helperText: "" }
      : {
          hasError: true,
          helperText: t("error_messages.incorrect_flight_number"),
        };
    setServiceErrors((prevState) => {
      return {
        ...prevState,
        flight_number: validationResult,
      };
    });
  };

  const validateCustomerEmail = (value) => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const validationResult = value.match(regex)
      ? { hasError: false, helperText: "" }
      : {
          hasError: true,
          helperText: t("error_messages.incorrect_format"),
        };
    setServiceErrors((prevState) => {
      return {
        ...prevState,
        email: validationResult,
      };
    });
  };

  const handleChangeInCheckBox = (e) => {
    let { checked, name } = e.target;
    setExtraServices((prevState) => {
      let newState = [];
      if (checked) {
        newState = [...prevState, name];
      } else {
        newState = prevState.filter((item) => item !== name);
      }
      return newState;
    });

    setContractServices((prevState) => {
      let newState = { ...prevState };
      if (checked) {
        if (newState[name] && newState[name].length) {
          newState[name] = newState[name].map((item) => {
            item["_destroy"] = "0";
            return item;
          });
        } else {
          newState[name] = [];
          let id = Math.random().toString(16).slice(2);
          let clonedService = {
            ...servicesArr.find((el) => el.code === name),
            _destroy: "0",
            id: id,
            template: false,
            yes_no: true,
            created_at: null,
            updated_at: null,
          };
          if (!servicesArr[name]) {
            clonedService.start_time = new Date();
            clonedService.end_time = new Date();
          }
          newState[name].push(clonedService);
        }
      } else {
        let keys = Object.keys(newState);
        for (let i in keys) {
          if (keys[i] === name) {
            newState[keys[i]] = newState[keys[i]]
              .filter((item) => Number.isInteger(item.id))
              ?.map((item) => {
                item["_destroy"] = "1";
                return item;
              });
          }
        }
      }
      return newState;
    });
  };

  const handleDeleteService = (serviceId, code) => {
    if (Number.isInteger(serviceId)) {
      setContractServices((prevState) => {
        let newState = { ...prevState };
        newState[code] = newState[code].map((item) => {
          if (item.id === serviceId) {
            item["_destroy"] = "1";
          }
          return item;
        });
        return newState;
      });
    } else {
      setContractServices((prevState) => {
        let newState = { ...prevState };
        newState[code] = newState[code].filter((item) => item.id !== serviceId);
        return newState;
      });
    }
  };

  const handleAddService = (code) => {
    let id = Math.random().toString(16).slice(2);
    let clonedService = {
      ...servicesArr.find((el) => el.code === code),
      _destroy: "0",
      id: id,
      template: false,
      yes_no: true,
      created_at: null,
      updated_at: null,
    };
    const firstItem = contractServices[code][0];
    if (sameTime[code]) {
      clonedService.start_time = firstItem.start_time;
      clonedService.end_time = firstItem.end_time;
    } else {
      clonedService.start_time = new Date();
      clonedService.end_time = new Date();
    }
    const flag =
      contractServices[code].find((el) => el["_destroy"] === "1") === undefined;
    setContractServices((prevState) => {
      let newState = { ...prevState };
      if (flag) {
        newState[code] = [...newState[code], clonedService];
      } else {
        let itemIndex = newState[code].indexOf(
          contractServices[code].find((el) => el["_destroy"] === "1")
        );
        newState[code][itemIndex]["_destroy"] = "0";
      }
      return newState;
    });
  };

  const handleTRLFormFieldChange = (fieldName, value) => {
    setService({
      ...service,
      [fieldName]: value,
      email: customers.find((el) => el.name === value).email1,
    });

    if (
      (fieldName !== "attention_of" && fieldName !== "note") ||
      (service.airline?.toLowerCase().includes("latam") && fieldName === "note")
    ) {
      validateEmpty(fieldName, value);
    }
    if (fieldName === "airline" && !value.toLowerCase().includes("latam")) {
      setServiceErrors((prevState) => {
        return { ...prevState, note: { hasError: false, helperText: "" } };
      });
      setService((prevState) => {
        return {
          ...prevState,
          note: "",
          email: customers.find((el) => el.name === value).email1,
        };
      });
    }
    if (
      fieldName === "flight_number" &&
      !["", undefined, null].includes(value)
    ) {
      validateFlightNumber(value);
    }

    if (fieldName === "email" && !["", undefined, null].includes(value)) {
      validateCustomerEmail(value);
    }
  };

  const handleFieldChange = (code, id, field, value) => {
    setContractServices((prevState) => {
      let newState = { ...prevState };
      let itemIndex = newState[code].indexOf(
        contractServices[code].find((el) => el.id === id)
      );
      newState[code][itemIndex][field] = value;
      if (sameTime[code]) {
        newState[code] = newState[code].map((item) => {
          item[field] = value;
          return item;
        });
      }
      return newState;
    });
  };

  const handleSameTimeChange = (code) => {
    setSameTime((prevState) => {
      let newState = { ...prevState };
      newState[code] = !newState[code];
      return newState;
    });
    const firstItem = contractServices[code][0];
    setContractServices((prevState) => {
      let newState = { ...prevState };
      newState[code] = newState[code].map((item) => {
        item.start_time = firstItem.start_time;
        item.end_time = firstItem.end_time;
        return item;
      });
      return newState;
    });
  };

  const handleCancelClick = () => {
    window.location.pathname =
      service.id === null
        ? `/admin/additional_service_forms`
        : `/admin/additional_service_forms/${service.id}`;
  };

  const handleSubmit = () => {
    // 1. Validate TRL Form fields
    [
      "trl_date",
      "flight_number",
      "airline",
      "aircraft_type",
      "tail_number",
      "email",
    ].forEach((field) => {
      if (
        field === "airline" &&
        service[field] &&
        service[field].toLowerCase().includes("latam")
      ) {
        validateEmpty("note", service.note);
      } else if (field === "flight_number" && service[field]) {
        validateFlightNumber(service.flight_number);
      } else if (field === "email" && service[field]?.length) {
        validateCustomerEmail(service.email);
      } else {
        validateEmpty(field, service[field]);
      }
    });

    // 2. Validate Additional Services fields
    let contract_services_attributes = {},
      contractServicesArray = [],
      index = 0;

    for (let key in contractServices) {
      contractServices[key].forEach((item) => {
        contractServicesArray.push(item);
      });
    }

    const errorsArray = contractServicesArray.reduce((obj, service) => {
      obj[service.id] = validateService(service);
      return obj;
    }, {});

    setErrors(errorsArray);

    setTimeout(() => {
      const formHasErrors = Object.values(serviceErrors)
        .map((element) => element.hasError)
        .includes(true);

      const childErrorsArray = [];
      const keys = Object.keys(errorsArray);
      for (let i = 0; i < keys.length; i++) {
        childErrorsArray.push(...Object.values(errorsArray[keys[i]]));
      }

      const childAttributesHasErrors = childErrorsArray.includes(true);
      if (!formHasErrors && !childAttributesHasErrors) {
        setShowAlertMessage(false);
        for (let key in contractServices) {
          contractServices[key].forEach((item) => {
            item["id"] = Number.isInteger(item["id"]) ? item["id"] : null;
            contract_services_attributes[index.toString()] = item;
            index += 1;
          });
        }
        const data = {
          trl_form: {
            ...service,
            contract_services_attributes,
          },
        };
        const request =
          service.id === null || service.id === undefined
            ? axios.post("/admin/additional_service_forms.json", data, {
                headers: {
                  "Content-Type": "application/json",
                },
              })
            : axios.patch(
                `/admin/additional_service_forms/${service.id}.json`,
                data,
                {
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              );

        request
          .then((response) => {
            const msg = alertify.success(t("success_messages.trl_form"), 2);
            msg.callback = function (isClicked) {
              window.location.pathname =
                response.status === 201
                  ? `/admin/additional_service_forms/${response.data.id}`
                  : `/admin/additional_service_forms/${service.id}`;
            };
          })
          .catch((error) => {
            alertify.error(t("error_messages.main"), 5);
          });
      } else {
        setShowAlertMessage(true);
      }
    }, 500);
  };

  const filteredFlights = (
    [null, undefined, ""].includes(service.airline)
      ? flights
      : flights.filter((flight) => flight.customer_name === service.airline)
  )
    .map((f) => [f.inbound, f.outbound])
    .flat(2)
    .sort()
    .map((f) => {
      return { label: f, value: f };
    });

  return (
    <I18nextProvider i18n={i18n}>
      <div id="trl_form">
        {showAlertMessage && (
          <div id="alert">
            <Alert severity="error" sx={{ marginBottom: "20px" }}>
              <AlertTitle>{t("error_messages.main")}</AlertTitle>
            </Alert>
          </div>
        )}
        <form>
          <FormGroup>
            <h3 className="d-flex justify-content-between">
              <span>
                {service.customer_type === ""
                  ? t("choose_option").toUpperCase()
                  : `${service.airline !== null ? service.airline : ""}`}
              </span>
              <span className="text-primary">
                {service.tail_number !== null && service.tail_number}
              </span>
            </h3>
            <RadioGroup
              aria-labelledby="flight-type"
              defaultValue=""
              name="radio-buttons-group"
              value={service.customer_type}
              onChange={changeCustomerTypeHandler}
            >
              <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                  <FormControlLabel
                    value="new"
                    control={<Radio />}
                    label={t("new_customer")}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormControlLabel
                    value="existing"
                    control={<Radio />}
                    label={t("existing_customer")}
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </FormGroup>

          {service.customer_type != "" && (
            <Fragment>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={6} md={3}>
                  <InputLabel
                    error={serviceErrors.trl_date.hasError}
                    required
                    sx={{ marginBottom: "10px" }}
                    htmlFor="trl_date"
                  >
                    {t("date")}
                  </InputLabel>
                  <Input
                    placeholder="Date"
                    id="trl_date"
                    name="trl_date"
                    type="date"
                    value={service.trl_date}
                    onChange={(e) => {
                      const { name, value } = e.target;
                      handleTRLFormFieldChange(name, value);
                    }}
                    sx={{ width: "100%" }}
                  />
                  <FormHelperText error={serviceErrors.trl_date.hasError}>
                    {serviceErrors.trl_date.helperText}
                  </FormHelperText>
                </Grid>
                <Grid item xs={6} md={3}>
                  <InputLabel
                    sx={{ marginBottom: "10px" }}
                    required
                    htmlFor="email"
                    error={serviceErrors.email.hasError}
                  >
                    {t("customer_email")}
                  </InputLabel>
                  <Input
                    placeholder={t("customer_email")}
                    id="email"
                    name="email"
                    type="email"
                    value={service.email}
                    onChange={(e) => {
                      const { name, value } = e.target;
                      handleTRLFormFieldChange(name, value);
                    }}
                    sx={{ width: "100%" }}
                  />
                  <FormHelperText error={serviceErrors.email.hasError}>
                    {serviceErrors.email.helperText}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={6} md={3}>
                  <InputLabel
                    required
                    sx={{ marginBottom: "10px" }}
                    htmlFor="airline"
                    error={serviceErrors.airline.hasError}
                  >
                    {t("airline")}
                  </InputLabel>
                  <CustomSelect
                    isClearable={![null, ""].includes(service.airline)}
                    isSearchable={true}
                    options={airlines}
                    id="airline"
                    name="airline"
                    isValid={true}
                    value={{
                      label: service.airline || "",
                      value: service.airline || "",
                    }}
                    onChange={(option) =>
                      handleTRLFormFieldChange("airline", option?.value)
                    }
                  />
                  <FormHelperText error={serviceErrors.airline.hasError}>
                    {serviceErrors.airline.helperText}
                  </FormHelperText>
                </Grid>
                {service.customer_type === "new" && (
                  <Grid item xs={6} md={3}>
                    <InputLabel
                      sx={{ marginBottom: "10px" }}
                      htmlFor="attention_of"
                      error={serviceErrors.attention_of.hasError}
                    >
                      {t("requested_by")}
                    </InputLabel>
                    <Input
                      placeholder={t("requested_by")}
                      id="attention_of"
                      name="attention_of"
                      type="text"
                      value={service.attention_of}
                      onChange={(e) => {
                        const { name, value } = e.target;
                        handleTRLFormFieldChange(name, value);
                      }}
                      sx={{ width: "100%" }}
                    />
                    <FormHelperText error={serviceErrors.attention_of.hasError}>
                      {serviceErrors.attention_of.helperText}
                    </FormHelperText>
                  </Grid>
                )}
                <Grid item xs={6} md={3}>
                  <InputLabel
                    sx={{ marginBottom: "10px" }}
                    htmlFor="supervisor"
                  >
                    Supervisor
                  </InputLabel>
                  <CustomSelect
                    isClearable={![null, ""].includes(service.supervisor)}
                    isSearchable={true}
                    options={supervisors}
                    id="supervisor"
                    name="supervisor"
                    isValid={true}
                    value={{
                      label: service.supervisor || "",
                      value: service.supervisor || "",
                    }}
                    onChange={(option) =>
                      setService((prevState) => {
                        return {
                          ...prevState,
                          supervisor: option?.value || "",
                        };
                      })
                    }
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <InputLabel
                    sx={{ marginBottom: "10px" }}
                    htmlFor="ramp_control"
                  >
                    {t("ramp_control")}
                  </InputLabel>
                  <CustomSelect
                    isClearable={![null, ""].includes(service.ramp_control)}
                    isSearchable={true}
                    options={ramp_controls}
                    id="ramp_control"
                    name="ramp_control"
                    isValid={true}
                    value={{
                      label: service.ramp_control || "",
                      value: service.ramp_control || "",
                    }}
                    onChange={(option) =>
                      setService((prevState) => {
                        return {
                          ...prevState,
                          ramp_control: option?.value || "",
                        };
                      })
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                  <InputLabel
                    sx={{ marginBottom: "10px" }}
                    htmlFor="flight_number"
                    required
                    error={serviceErrors.flight_number.hasError}
                  >
                    {t("flight_number")}
                  </InputLabel>
                  <CreatableSelect
                    isClearable={![null, ""].includes(service.flight_number)}
                    isSearchable={true}
                    options={filteredFlights}
                    id="flight_number"
                    name="flight_number"
                    isValid={true}
                    value={{
                      label: service.flight_number || "",
                      value: service.flight_number || "",
                    }}
                    onChange={(option) =>
                      handleTRLFormFieldChange("flight_number", option?.value)
                    }
                  />
                  <FormHelperText error={serviceErrors.flight_number.hasError}>
                    {serviceErrors.flight_number.helperText}
                  </FormHelperText>
                </Grid>
                {service.airline?.toLowerCase()?.includes("latam") && (
                  <Grid item xs={6} md={3}>
                    <InputLabel
                      sx={{ marginBottom: "10px" }}
                      htmlFor="note"
                      error={serviceErrors.note.hasError}
                      required
                    >
                      {t("note")}
                    </InputLabel>
                    <CustomSelect
                      isClearable={![null, ""].includes(service.note)}
                      isSearchable={true}
                      options={[
                        { label: "Operation", value: "Operation" },
                        { label: "Maintenance", value: "Maintenance" },
                        { label: "Cargo", value: "Cargo" },
                      ]}
                      id="note"
                      name="note"
                      isValid={true}
                      value={{
                        label: service.note || "",
                        value: service.note || "",
                      }}
                      onChange={(option) =>
                        handleTRLFormFieldChange("note", option?.value)
                      }
                    />
                    <FormHelperText error={serviceErrors.note.hasError}>
                      {serviceErrors.note.helperText}
                    </FormHelperText>
                  </Grid>
                )}
                <Grid item xs={6} md={3}>
                  <InputLabel
                    required
                    sx={{ marginBottom: "10px" }}
                    htmlFor="aircraft_type"
                    error={serviceErrors.aircraft_type.hasError}
                  >
                    {t("aircraft_type")}
                  </InputLabel>
                  <CustomSelect
                    isClearable={![null, ""].includes(service.aircraft_type)}
                    isSearchable={true}
                    options={aircrafts}
                    id="aircraft_type"
                    name="aircraft_type"
                    isValid={true}
                    value={{
                      label: service.aircraft_type || "",
                      value: service.aircraft_type || "",
                    }}
                    onChange={(option) =>
                      handleTRLFormFieldChange("aircraft_type", option?.value)
                    }
                  />
                  <FormHelperText error={serviceErrors.aircraft_type.hasError}>
                    {serviceErrors.aircraft_type.helperText}
                  </FormHelperText>
                </Grid>
                <Grid item xs={6} md={3}>
                  <InputLabel
                    required
                    sx={{ marginBottom: "10px" }}
                    htmlFor="tail_number"
                    error={serviceErrors.tail_number.hasError}
                  >
                    {t("tail_number")}
                  </InputLabel>
                  <Input
                    placeholder={t("tail_number")}
                    id="tail_number"
                    name="tail_number"
                    type="text"
                    value={service.tail_number}
                    onChange={(e) => {
                      const { name, value } = e.target;
                      handleTRLFormFieldChange(
                        name,
                        value?.toLocaleUpperCase()
                      );
                    }}
                    sx={{ width: "100%" }}
                  />
                  <FormHelperText error={serviceErrors.tail_number.hasError}>
                    {serviceErrors.tail_number.helperText}
                  </FormHelperText>
                </Grid>
              </Grid>
              <hr style={{ marginTop: "25px" }} />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <h3 className="text-center">{t("services_performed")}</h3>
                </Grid>
                <Grid item xs={6}>
                  <h4>{t("mostly_used")}</h4>
                  {mostly_used.map((service) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={service.code}
                          checked={
                            extraServices?.length > 0 &&
                            extraServices?.includes(service.code)
                          }
                          onChange={handleChangeInCheckBox}
                        />
                      }
                      label={service.name}
                      key={service.id}
                      sx={{ width: "100%" }}
                    />
                  ))}
                </Grid>
                <Grid item xs={6}>
                  <h4>{t("other")}</h4>
                  {other_services.map((service) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={service.code}
                          checked={
                            extraServices?.length > 0 &&
                            extraServices?.includes(service.code)
                          }
                          onChange={handleChangeInCheckBox}
                        />
                      }
                      label={service.name}
                      key={service.id}
                      sx={{ width: "100%" }}
                    />
                  ))}
                </Grid>
              </Grid>
              <hr style={{ marginTop: "25px" }} />

              {extraServices.map((code) => {
                return (
                  <div key={code} className="extra-services-section">
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{
                        padding: "20px",
                      }}
                    >
                      <h3 style={{ marginBottom: 0 }}>
                        {servicesArr.find((el) => el.code === code).name}
                      </h3>
                      <div>
                        {servicesArr.find((item) => item.code === code)
                          .enable_start_end_time && (
                          <IconButton
                            variant="contained"
                            color={sameTime[code] ? "error" : "info"}
                            onClick={handleSameTimeChange.bind(this, code)}
                          >
                            {sameTime[code] ? (
                              <LockClockIcon
                                fontSize="large"
                                sx={{ borderRadius: "50%" }}
                              />
                            ) : (
                              <WatchLaterIcon
                                fontSize="large"
                                sx={{ boxShadow: 2, borderRadius: "50%" }}
                              />
                            )}
                          </IconButton>
                        )}
                        <IconButton
                          variant="contained"
                          color="info"
                          onClick={handleAddService.bind(this, code)}
                        >
                          <AddCircleIcon
                            fontSize="large"
                            sx={{ boxShadow: 2, borderRadius: "50%" }}
                          />
                        </IconButton>
                      </div>
                    </div>
                    {contractServices[code] &&
                      contractServices[code].map((service, currentIndex) => {
                        const validationErrors = errors[
                          service.id?.toString()
                        ] || {
                          unit1: false,
                          unit2: false,
                          unit3: false,
                          unit4: false,
                          unit5: false,
                          signer_name: false,
                          start_loc: false,
                          dest: false,
                          start_time: false,
                          end_time: false,
                        };

                        if (
                          (service.enable_start_end_time &&
                            typeof service.start_time === "string") ||
                          (service.enable_start_end_time &&
                            typeof service.end_time === "string")
                        ) {
                          handleFieldChange(
                            code,
                            service.id,
                            "start_time",
                            new Date(service.start_time)
                          );
                          handleFieldChange(
                            code,
                            service.id,
                            "end_time",
                            new Date(service.end_time)
                          );
                        }
                        if (service["_destroy"] === "0") {
                          return (
                            <MuiThemeProvider theme={theme} key={service.id}>
                              <AdminContractService
                                service={service}
                                setErrors={setErrors}
                                errors={validationErrors}
                                index={currentIndex + 1}
                                onDelete={handleDeleteService.bind(
                                  this,
                                  service.id,
                                  code
                                )}
                                serviceCode={code}
                                onChange={handleFieldChange}
                              />
                            </MuiThemeProvider>
                          );
                        }
                      })}
                  </div>
                );
              })}
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ my: 4 }}
              >
                <ButtonGroup
                  size="large"
                  variant="contained"
                  aria-label="contained button group"
                >
                  <Button color="info" onClick={handleSubmit}>
                    {service.id == null ? <AddIcon /> : <UpdateIcon />}
                    {service.id == null ? t("create") : t("update")}
                  </Button>
                  <Button color="inherit" onClick={handleCancelClick}>
                    {t("cancel")}
                  </Button>
                </ButtonGroup>
              </Grid>
            </Fragment>
          )}
        </form>
      </div>
    </I18nextProvider>
  );
};

export default TRLForm;
