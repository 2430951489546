import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const PassengerServicesChart = ({ data }) => (
  <ResponsiveContainer width="100%" height={400}>
    <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="ac_type" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="Arrival" fill="#82ca9d" name="Arrivals" />
      <Bar dataKey="Departure" fill="#8884d8" name="Departures" />
    </BarChart>
  </ResponsiveContainer>
);

export default PassengerServicesChart;
