import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { customStyles } from "../../styles";
import {
  InputLabel,
  ButtonGroup,
  Button,
  Alert,
  AlertTitle,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Close from "@mui/icons-material/Close";
import CustomSelect from "../ui/CustomSelect";

const CancelFlightForm = ({
  showForm,
  cancelFlight,
  setCancelFlight,
  onClickClose,
  initialCancelFlight,
  createRecord,
  t,
}) => {
  const revokedServices = [
    { label: t("ramp_service"), value: "ramp" },
    { label: t("passenger_service"), value: "passenger" },
    { label: t("security_cabin_search"), value: "security" },
    { label: t("cabin_service_report"), value: "cleaning" },
  ];

  const initialErrors = {
    date_notified: { value: false, text: "" },
    reason: { value: false, text: "" },
    notes: { value: false, text: "" },
    revoked_services: { value: true, text: "" },
  };
  const [showAlert, setShowAlert] = useState(false);
  const [errors, setErrors] = useState(initialErrors);
  const [services, setServices] = useState([]);

  useEffect(() => {
    customStyles.content.height = "75vh";
  }, []);

  useEffect(() => {
    setErrors(initialErrors);
  }, [onClickClose]);

  useEffect(() => {
    setCancelFlight((prevState) => {
      let newState = { ...prevState };
      newState["revoked_services"] = services.map((s) => s["value"]).join(",");
      return newState;
    });
    setErrors((prevState) => ({
      ...prevState,
      revoked_services: { text: "", value: true },
    }));
  }, [services, setServices]);

  const closeModal = () => {
    setCancelFlight(initialCancelFlight);
    onClickClose(false);
    customStyles.content.height = "";
  };

  const onServicesSelectHandler = (option) => {
    setServices(option);
    setErrors((prevState) => ({
      ...prevState,
      revoked_services: { text: "", value: true },
    }));
  };

  const onFormSubmitHandler = () => {
    ["date_notified", "reason", "notes", "revoked_services"].forEach((prop) => {
      if (!cancelFlight[prop]) {
        setErrors((prevState) => {
          const newState = { ...prevState };
          newState[prop].value = prop === "revoked_services" ? false : true;
          newState[prop].text = t("error_messages.not_empty");
          return newState;
        });
        setShowAlert(true);
      } else {
        setErrors((prevState) => {
          const newState = { ...prevState };
          newState[prop].value = prop === "revoked_services" ? true : false;
          newState[prop].text = "";
          return newState;
        });
        setShowAlert(false);
      }
    });

    if (
      cancelFlight.date_notified != null &&
      cancelFlight.reason &&
      cancelFlight.notes &&
      cancelFlight.revoked_services &&
      !errors.date_notified.value &&
      !errors.reason.value &&
      !errors.notes.value &&
      errors.revoked_services.value
    ) {
      const data = { cancelled_flight: { ...cancelFlight } };
      data.cancelled_flight.date_notified = cancelFlight.date_notified.format();
      createRecord(data);
    }
  };

  const inputChangeHandler = (newValue, prop) => {
    setCancelFlight((prevState) => {
      const newState = { ...prevState };
      newState[prop] = newValue;
      return newState;
    });

    setErrors((prevState) => {
      const newState = { ...prevState };
      newState[prop].value = !newValue;
      newState[prop].text = !newValue ? t("error_messages.not_empty") : "";
      return newState;
    });
  };

  return (
    <Modal
      isOpen={showForm}
      style={customStyles}
      closeTimeoutMS={600}
      contentLabel="Cancel Service Modal"
      ariaHideApp={false}
    >
      <div style={{ mb: "10px", display: "flex", justifyContent: "right" }}>
        <Close onClick={closeModal} sx={{ cursor: "pointer" }} />
      </div>
      <h2 style={{ textAlign: "center" }}>{t("cancelation_form")}</h2>
      {showAlert && (
        <Alert severity="error" style={{ marginBottom: "20px" }}>
          <AlertTitle>{t("error_messages.main1")}</AlertTitle>
        </Alert>
      )}
      <form>
        <InputLabel
          sx={{ marginBottom: "10px" }}
          htmlFor="date_notified"
          required
        >
          {t("date_notified")}
        </InputLabel>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            slotProps={{
              textField: {
                error: errors.date_notified.value,
                helperText: errors.date_notified.text,
              },
            }}
            sx={{ width: "100%" }}
            value={cancelFlight.date_notified}
            onChange={(newValue) =>
              inputChangeHandler(newValue, "date_notified")
            }
          />
        </LocalizationProvider>
        <br />
        <br />
        <InputLabel sx={{ marginBottom: "10px" }} htmlFor="flight_date">
          {t("flight_date")}
        </InputLabel>
        <input
          placeholder={t("date")}
          id="flight_date"
          name="flight_date"
          type="date"
          value={cancelFlight.original_date}
          readOnly={true}
        />
        <br />
        <InputLabel sx={{ marginBottom: "10px" }} htmlFor="flight_in">
          {t("flight_number")}
        </InputLabel>
        <input
          placeholder={t("flight_number")}
          id="flight_number"
          name="flight_number"
          type="text"
          value={cancelFlight.flight_number}
          readOnly={true}
        />
        <br />
        <InputLabel sx={{ marginBottom: "10px" }} htmlFor="revoked_services">
          {t("revoked_services")}
        </InputLabel>
        <CustomSelect
          isMulti
          options={revokedServices}
          id="revoked_services"
          name="revoked_services"
          onChange={onServicesSelectHandler}
          value={services}
          isValid={errors.revoked_services.value}
          errorText={errors.revoked_services.text}
        />
        <br />
        <InputLabel sx={{ marginBottom: "10px" }} htmlFor="reason" required>
          {t("reason")}
        </InputLabel>
        <TextField
          placeholder={t("reason")}
          id="reason"
          fullWidth
          variant="outlined"
          error={errors.reason.value}
          helperText={errors.reason.text}
          value={cancelFlight.reason}
          onChange={(e) => inputChangeHandler(e.target.value, "reason")}
        />
        <br />
        <br />
        <InputLabel sx={{ marginBottom: "10px" }} htmlFor="reason" required>
          {t("note")}
        </InputLabel>
        <TextField
          placeholder={t("note")}
          id="notes"
          fullWidth
          variant="outlined"
          error={errors.notes.value}
          helperText={errors.notes.text}
          value={cancelFlight.notes}
          onChange={(e) => inputChangeHandler(e.target.value, "notes")}
        />
        <br />
        <div className="space"></div>
        <br />
        <ButtonGroup variant="text" aria-label="button group" fullWidth={true}>
          <Button color="primary" onClick={onFormSubmitHandler}>
            {t("submit")}
          </Button>
          <Button onClick={closeModal} className="cancel" color="error">
            {t("close")}
          </Button>
        </ButtonGroup>
        <br />
      </form>
    </Modal>
  );
};

export default CancelFlightForm;
