import React, { useState } from "react";
import {
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import { toHoursAndMinutes, formatDateUS } from "../helpers";

const DelayedFlightsReport = ({ data }) => {
  const [timeCategory, setTimeCategory] = useState("30M+");
  const [flightType, setFlightType] = useState("Arrival");

  const handleTimeCategoryChange = (event, newValue) => {
    setTimeCategory(newValue);
  };

  const handleFlightTypeChange = (event, newValue) => {
    setFlightType(newValue);
  };

  return (
    <Box>
      <Tabs
        value={timeCategory}
        onChange={handleTimeCategoryChange}
        centered
        indicatorColor="primary"
        textColor="primary"
      >
        {Object.keys(data).map((category) => (
          <Tab key={category} label={category} value={category} />
        ))}
      </Tabs>
      <Tabs
        value={flightType}
        onChange={handleFlightTypeChange}
        centered
        indicatorColor="secondary"
        textColor="secondary"
        style={{ marginTop: "10px" }}
      >
        <Tab label="Arrival" value="Arrival" />
        <Tab label="Departure" value="Departure" />
      </Tabs>

      <TableContainer component={Paper} style={{ marginTop: "20px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>
                Flight Number
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                Aircraft Type
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Date</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                Scheduled Time
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Actual Time</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Gate</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>City</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Tail Number</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data[timeCategory] && data[timeCategory][flightType].length > 0 ? (
              data[timeCategory][flightType].map((flight) => (
                <TableRow key={flight.id}>
                  <TableCell>{flight.flight_number}</TableCell>
                  <TableCell>{flight.ac_type}</TableCell>
                  <TableCell>{formatDateUS(flight.date)}</TableCell>
                  <TableCell>
                    {toHoursAndMinutes(flight.scheduled_time)}
                  </TableCell>
                  <TableCell>{toHoursAndMinutes(flight.actual_time)}</TableCell>
                  <TableCell>{flight.gate}</TableCell>
                  <TableCell>{flight.city}</TableCell>
                  <TableCell>{flight.tail_number}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  No flights available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DelayedFlightsReport;
