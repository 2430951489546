export const toHoursAndMinutes = (totalSeconds) => {
  const totalMinutes = Math.floor(totalSeconds / 60);
  const hours =
    Math.floor(totalMinutes / 60) < 10
      ? `0${Math.floor(totalMinutes / 60)}`
      : Math.floor(totalMinutes / 60);
  const minutes =
    totalMinutes % 60 < 10 ? `0${totalMinutes % 60}` : totalMinutes % 60;

  return `${hours}:${minutes}`;
};

export const isObjEmpty = (obj) => {
  for (let property in obj) {
    if (obj.hasOwnProperty(property)) {
      return false;
    }
  }
  return true;
};

export const onlyUnique = (value, index, array) => {
  return array.indexOf(value) === index;
};

export const validateService = (service) => {
  const errors = {
    unit1:
      service.enable_unit &&
      ![null, ""].includes(service.label_unit1) &&
      [null, ""].includes(service.unit1),
    unit2:
      service.enable_unit &&
      ![null, ""].includes(service.label_unit2) &&
      [null, ""].includes(service.unit2),
    unit3:
      service.enable_unit &&
      ![null, ""].includes(service.label_unit3) &&
      [null, ""].includes(service.unit3),
    unit4:
      service.enable_unit &&
      ![null, ""].includes(service.label_unit4) &&
      [null, ""].includes(service.unit4),
    unit5:
      service.enable_unit &&
      ![null, ""].includes(service.label_unit5) &&
      [null, ""].includes(service.unit5),
    signature:
      service.signature_required && [null, ""].includes(service.signature),
    signer_name: service.signature_required && service.signer_name == "",
    start_loc:
      service.enable_start_loc_dest &&
      ![null, ""].includes(service.label_start_loc) &&
      [null, ""].includes(service.start_loc),
    dest:
      service.enable_start_loc_dest &&
      ![null, ""].includes(service.label_dest) &&
      [null, ""].includes(service.dest),
    start_time:
      service.enable_start_end_time &&
      ![null, ""].includes(service.label_start_time) &&
      service.start_time === null,
    end_time:
      (service.enable_start_end_time &&
        ![null, ""].includes(service.label_end_time) &&
        service.end_time === null) ||
      (![null, ""].includes(service.label_end_time) &&
        service.end_time.getTime() <= service.start_time.getTime()),
  };
  return errors;
};

export const secondsToDateObject = (totalSeconds) => {
  var t = new Date(1970, 0, 1);
  t.setSeconds(totalSeconds);
  return t;
};

export const formatDateInt = (dateString) => {
  let date = new Date(dateString);
  let options = {
    weekday: "short",
    day: "2-digit",
    month: "short",
    year: "numeric",
  };

  let formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);

  formattedDate = formattedDate.replace(/,/g, "").toUpperCase();

  return formattedDate;
};

export const getDate = (flightDate) => {
  const d = typeof flightDate === "string" ? new Date(flightDate) : flightDate;
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const day = d.getDate();
  const date =
    year +
    "-" +
    (month < 10 ? `0${month}` : month) +
    "-" +
    (day < 10 ? `0${day}` : day);
  return date;
};

export const getTime = (date) => {
  let d = typeof date === "string" ? new Date(date) : date;
  let time =
    (d?.getHours() < 10 ? `0${d?.getHours()}` : d?.getHours()) +
    ":" +
    (d?.getMinutes() < 10 ? `0${d?.getMinutes()}` : d?.getMinutes());

  return time;
};

export const isInArray = (array, value) => {
  return (
    (
      array.find((item) => {
        return item == value;
      }) || []
    ).length > 0
  );
};

export const formatDate = (date) => {
  let d = typeof date === "string" ? new Date(date) : date,
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const formatDateUS = (date) => {
  if (date === null || date === undefined) {
    return "-";
  } else {
    const [year, month, day] = date.split("-");
    return [month, day, year].join("/");
  }
};

export const setServiceTime = (flight, type) => {
  let [hrs, mins] =
    type == "arrival"
      ? (flight.eta !== "-" ? flight.eta : flight.sta).split(":")
      : (flight.etd !== "-" ? flight.etd : flight.std).split(":");
  hrs =
    type == "arrival"
      ? parseInt(hrs) + 1
      : parseInt(hrs) === 0
      ? 23
      : parseInt(hrs) - 1;
  hrs = hrs < 10 ? `0${hrs}` : hrs.toString();
  const formattedTime = [hrs, mins].join(":");
  const time =
    type == "arrival"
      ? {
          start: `${formatDate(flight.date_a)}T${
            flight.eta !== "-" ? flight.eta : flight.sta
          }`,
          end: `${formatDate(flight.date_a)}T${formattedTime}`,
        }
      : {
          start: `${formatDate(flight.date_d)}T${formattedTime}`,
          end: `${formatDate(flight.date_d)}T${
            flight.etd !== "-" ? flight.etd : flight.std
          }`,
        };
  return time;
};

export const formatDateAndTime = (date, time_only = false) => {
  let d = typeof date === "string" ? new Date(date) : date;
  var hours = d.getHours();
  var minutes = d.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = (hours > 9 ? hours : `0${hours}`) + ":" + minutes + " " + ampm;
  return time_only
    ? strTime
    : d.getMonth() +
        1 +
        "/" +
        d.getDate() +
        "/" +
        d.getFullYear() +
        "  " +
        strTime;
};

export const isDiverted = (arr) => arr.some((flight) => flight.diverted);

export const findIndexInbound = (arr) => {
  for (let i = 0; i < arr.length; i++) {
    if (
      arr[i].scheduled_in &&
      arr[i].estimated_in &&
      arr[i].registration &&
      arr[i].status !== "result unknown" &&
      ["MCO", "FLL", "JFK", "BOS", "MIA"].includes(arr[i].destination.code_iata)
    ) {
      return i;
    }
  }
  // Return the fallback value if no match is found
  return arr.findIndex(
    (item) =>
      item.scheduled_in &&
      item.status !== "result unknown" &&
      ["MCO", "FLL", "JFK", "BOS", "MIA"].includes(item.destination.code_iata)
  );
};

export const findIndexOutbound = (arr) => {
  for (let i = 0; i < arr.length; i++) {
    if (
      arr[i].scheduled_out &&
      arr[i].estimated_out &&
      arr[i].status !== "result unknown" &&
      ["MCO", "FLL", "JFK", "BOS", "MIA"].includes(arr[i].origin.code_iata)
    ) {
      return i;
    }
  }

  // Return the fallback value if no match is found
  return arr.findIndex(
    (item) =>
      item.scheduled_out &&
      item.status !== "result unknown" &&
      ["MCO", "FLL", "JFK", "BOS", "MIA"].includes(item.origin.code_iata)
  );
};

export const clearService = (service, date, time) => {
  const correctedTime = time.length === 4 ? `0${time}` : time;
  service.unit1 = "";
  service.unit2 = "";
  service.unit3 = "";
  service.unit4 = "";
  service.unit5 = "";
  service.start_loc = "";
  service.dest = "";
  service.signer_name = "";
  service.signature = "";
  service.shared = false;
  service.start_time = new Date(`${date}T${correctedTime}`);
  service.end_time = new Date(`${date}T${correctedTime}`);
  service.yes_no = true;
  return { ...service };
};

export const formatTimeEastern = (dateTimeString) => {
  let date = new Date(dateTimeString);
  console.log(date);
  let options = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZone: "America/New_York",
  };

  let formattedTime = new Intl.DateTimeFormat("en-US", options).format(date);

  formattedTime = formattedTime.replace(/\s/g, "").toUpperCase();

  return formattedTime;
};

export const formatTableData = (rows, cookieDate, services) => {
  const rowsData = [];
  if (Object.keys(rows).length > 0) {
    for (let id in rows) {
      const publicScheduleInData =
        rows[id]?.inbound?.data?.scheduled &&
        rows[id]?.inbound?.data?.scheduled?.length > 0;

      const publicScheduleOutData =
        rows[id]?.outbound?.data?.scheduled &&
        rows[id]?.outbound?.data?.scheduled?.length > 0;

      const inDataPresent =
        rows[id]?.inbound?.data?.flights &&
        rows[id]?.inbound?.data?.flights?.length > 0;

      const outDataPresent =
        rows[id]?.outbound?.data?.flights &&
        rows[id]?.outbound?.data?.flights?.length > 0;

      const multiInData =
        rows[id]?.inbound?.data?.flights &&
        rows[id]?.inbound?.data?.flights?.length >= 2;
      const multiOutData =
        rows[id]?.outbound?.data?.flights &&
        rows[id]?.outbound?.data?.flights?.length >= 2;
      const indx = multiInData
        ? findIndexInbound(rows[id].inbound.data.flights)
        : 0;
      const oIndx = multiOutData
        ? findIndexOutbound(rows[id].outbound.data.flights)
        : 0;
      const inDiverted = isDiverted(rows[id]?.inbound?.data?.flights || []);
      const outDiverted = isDiverted(rows[id]?.outbound?.data?.flights || []);

      const t1 =
        inDataPresent && rows[id]?.inbound?.data?.flights[indx]?.registration
          ? rows[id]?.inbound?.data?.flights[indx]?.registration
          : "-";
      const t2 =
        outDataPresent && rows[id]?.outbound?.data?.flights[oIndx]?.registration
          ? rows[id]?.outbound?.data?.flights[oIndx]?.registration
          : "-";
      const scheduledService = services.filter(
        (service) =>
          (service.flight_id === parseInt(id) &&
            service.flight_date === cookieDate) ||
          (service.flight_id === parseInt(id) &&
            service.flight_date2 === cookieDate)
      );
      let service = "-";
      if (scheduledService.length > 1) {
        service = "A/D";
      } else if (scheduledService.length == 1 && scheduledService[0].arrival) {
        service = "A";
      } else if (
        scheduledService.length == 1 &&
        scheduledService[0].departure
      ) {
        service = "D";
      }

      if (
        (![null, ""].includes(rows[id].inbound?.number) && inDataPresent) ||
        (![null, ""].includes(rows[id].outbound?.number) && outDataPresent)
      ) {
        const inboundFlights = rows[id]?.inbound?.data?.flights;
        const outboundFlights = rows[id]?.outbound?.data?.flights;
        rowsData.push({
          id: id,
          date: cookieDate,
          line_id1: rows[id]?.inbound?.line_id,
          line_id2: rows[id]?.outbound?.line_id,
          inbound_fa_flight_id: inboundFlights
            ? rows[id]?.inbound?.data?.flights[0]?.fa_flight_id
            : undefined,
          outbound_fa_flight_id: outboundFlights
            ? rows[id]?.outbound?.data?.flights[0]?.fa_flight_id
            : undefined,
          date_a:
            inDataPresent &&
            rows[id]?.inbound?.data?.flights[indx]?.scheduled_in
              ? formatDateUS(
                  getDate(rows[id].inbound.data.flights[indx].scheduled_in)
                )
              : "-",
          date_d:
            outDataPresent &&
            rows[id]?.outbound?.data?.flights[oIndx]?.scheduled_out
              ? formatDateUS(
                  getDate(rows[id].outbound.data.flights[oIndx].scheduled_out)
                )
              : "-",
          in: rows[id]?.inbound?.number,
          out: rows[id]?.outbound?.number,
          reg1: t1,
          reg2: t2,
          in_diverted: inDiverted,
          out_diverted: outDiverted,
          from:
            inDataPresent &&
            rows[id]?.inbound?.data?.flights[indx]?.origin?.code_iata
              ? rows[id].inbound.data.flights[indx].origin.code_iata
              : "-",
          dest:
            outDataPresent &&
            rows[id]?.outbound?.data?.flights[oIndx]?.destination?.code_iata
              ? rows[id].outbound.data.flights[oIndx].destination.code_iata
              : "-",
          sta:
            inDataPresent &&
            rows[id]?.inbound?.data?.flights[indx]?.scheduled_in
              ? getTime(rows[id].inbound.data.flights[indx].scheduled_in)
              : "-",
          eta:
            inDataPresent &&
            rows[id]?.inbound?.data?.flights[indx]?.estimated_in
              ? getTime(rows[id].inbound.data.flights[indx].estimated_in)
              : "-",
          std:
            outDataPresent &&
            rows[id]?.outbound?.data?.flights[oIndx]?.scheduled_out
              ? getTime(rows[id].outbound.data.flights[oIndx].scheduled_out)
              : "-",
          etd:
            outDataPresent &&
            rows[id]?.outbound?.data?.flights[oIndx]?.estimated_out
              ? getTime(rows[id].outbound.data.flights[oIndx].estimated_out)
              : "-",
          ata:
            inDataPresent && rows[id]?.inbound?.data?.flights[indx]?.actual_in
              ? getTime(rows[id].inbound.data.flights[indx].actual_in)
              : inDataPresent &&
                rows[id]?.inbound?.data?.flights[indx]?.actual_on
              ? getTime(rows[id].inbound.data.flights[indx].actual_on)
              : "-",
          atd:
            outDataPresent &&
            rows[id]?.outbound?.data?.flights[oIndx]?.actual_out
              ? getTime(rows[id].outbound.data.flights[oIndx].actual_out)
              : outDataPresent &&
                rows[id]?.outbound?.data?.flights[oIndx]?.actual_off
              ? getTime(rows[id].outbound.data.flights[oIndx].actual_off)
              : "-",
          arrival_delay:
            inDataPresent &&
            rows[id]?.inbound?.data?.flights[indx]?.arrival_delay
              ? rows[id].inbound.data.flights[indx].arrival_delay
              : 0,
          departure_delay:
            outDataPresent &&
            rows[id]?.outbound?.data?.flights[oIndx]?.departure_delay
              ? rows[id].outbound.data.flights[oIndx].departure_delay
              : 0,
          progress_percent1:
            inDataPresent &&
            rows[id]?.inbound?.data?.flights[indx]?.progress_percent
              ? rows[id].inbound.data.flights[indx].progress_percent
              : 0,
          progress_percent2:
            outDataPresent &&
            rows[id]?.outbound?.data?.flights[oIndx]?.progress_percent
              ? rows[id].outbound?.data.flights[oIndx].progress_percent
              : 0,
          flight_status:
            inDataPresent && rows[id]?.inbound?.data?.flights[indx]?.status
              ? rows[id].inbound.data.flights[indx].status
              : "-",
          flight_dstatus:
            outDataPresent && rows[id]?.outbound?.data?.flights[oIndx]?.status
              ? rows[id].outbound?.data.flights[oIndx].status
              : "-",
          aircraft_type:
            inDataPresent &&
            rows[id]?.inbound?.data?.flights[indx]?.aircraft_type
              ? rows[id].inbound.data.flights[indx].aircraft_type
              : "No data",
          aircraft_dtype:
            outDataPresent &&
            rows[id]?.outbound?.data?.flights[oIndx]?.aircraft_type
              ? rows[id].outbound?.data.flights[oIndx].aircraft_type
              : "No data",
          gate: inDataPresent
            ? rows[id]?.inbound?.data?.flights[0]?.gate_destination ||
              rows[id]?.inbound?.data?.flights[indx]?.gate_destination
            : "-",
          dgate: outDataPresent
            ? rows[id]?.outbound?.data?.flights[0]?.gate_origin ||
              rows[id]?.outbound?.data?.flights[oIndx]?.gate_origin
            : "-",
          service: service,
        });
      } else {
        rowsData.push({
          id: id,
          date: cookieDate,
          date_a:
            publicScheduleInData &&
            rows[id].inbound?.data?.scheduled[indx]?.scheduled_in
              ? formatDateUS(
                  getDate(rows[id].inbound?.data?.scheduled[indx]?.scheduled_in)
                )
              : "-",
          date_d:
            publicScheduleOutData &&
            rows[id].outbound?.data?.scheduled[oIndx]?.scheduled_out
              ? formatDateUS(
                  getDate(
                    rows[id].outbound?.data?.scheduled[oIndx].scheduled_out
                  )
                )
              : "-",
          in: rows[id]?.inbound?.number,
          out: rows[id]?.outbound?.number,
          line_id1: rows[id]?.inbound?.line_id,
          line_id2: rows[id]?.outbound?.line_id,
          reg: "-",
          from: publicScheduleInData
            ? rows[id]?.inbound?.data?.scheduled[indx].origin_iata
            : "-",
          dest: publicScheduleOutData
            ? rows[id]?.outbound?.data?.scheduled[indx].destination_iata
            : "-",
          sta: publicScheduleInData
            ? getTime(rows[id]?.inbound?.data?.scheduled[indx].scheduled_in)
            : "-",
          eta: publicScheduleInData
            ? getTime(rows[id]?.inbound?.data?.scheduled[indx].scheduled_in)
            : "-",
          std: publicScheduleOutData
            ? getTime(rows[id]?.outbound?.data?.scheduled[oIndx].scheduled_out)
            : "-",
          etd: publicScheduleOutData
            ? getTime(rows[id]?.outbound?.data?.scheduled[oIndx].scheduled_out)
            : "-",
          ata: "-",
          atd: "-",
          in_diverted: inDiverted,
          out_diverted: outDiverted,
          progress_percent1: 0,
          progress_percent2: 0,
          flight_status: publicScheduleInData ? "Scheduled" : "-",
          flight_dstatus: publicScheduleOutData ? "Scheduled" : "-",
          aircraft_type: publicScheduleInData
            ? rows[id]?.inbound?.data?.scheduled[indx].aircraft_type
            : "-",
          aircraft_dtype: publicScheduleOutData
            ? rows[id]?.outbound?.data?.scheduled[oIndx].aircraft_type
            : "-",
          gate: "-",
          dgate: "-",
          service: service,
        });
      }
    }
  }
  return rowsData;
};

export const num2time = (num) => {
  let ms = num % 1000;
  num = (num - ms) / 1000;
  let secs = num % 60;
  num = (num - secs) / 60;
  let mins = num % 60;
  let hrs = (num - mins) / 60;
  hrs = hrs < 10 ? `0${hrs}` : hrs;
  mins = mins < 10 ? `0${mins}` : mins;
  return hrs + ":" + mins;
};

export const numSec2time = (num) => {
  let secs = num % 60;
  num = (num - secs) / 60;
  let mins = num % 60;
  let hrs = (num - mins) / 60;
  hrs = hrs < 10 ? `0${hrs}` : hrs;
  mins = mins < 10 ? `0${mins}` : mins;
  return hrs + ":" + mins;
};

export const isInteger = (value) => {
  return Number.isInteger(value);
};

export const isValidDate = (dateString) => {
  const date = new Date(dateString);
  return date instanceof Date && !isNaN(date);
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatServiceDataForReport = (services) => {
  return {
    columns: {
      airline: "Customer",
      aircraft_model: "Aircraft Model",
      tail_number: "Tail Number",
      code: "Code",
      date: "Date",
      start_time: "Start Time",
      end_time: "End Time",
    },
    items: services
      .sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
      })
      .map((service) => ({
        airline: service.airline,
        aircraft_model: service.aircraft_model,
        tail_number: service.tail_number,
        code: service.code,
        date: formatDateUS(service.date),
        start_time: service.enable_start_end_time
          ? formatDateAndTime(service.start_time, true)
          : "-",
        end_time: service.enable_start_end_time
          ? formatDateAndTime(service.end_time, true)
          : "-",
      })),
  };
};
