import React, { useEffect, useState, useRef } from "react";
import SearchForm from "./ui/SearchForm";
import { useTranslation } from "react-i18next";
import { Grid, Box, Typography, Paper } from "@mui/material";
import i18n from "../i18n";
import axios from "axios";
import CBarChart from "./ui/CBarChart";
import CPieChart from "./ui/CPieChart";
import PassengerServicesChart from "./ui/PassengerServicesChart";
import ReportSection from "./ui/ReportSection";
import ServicesTabs from "./ui/ServicesTabs";
import DelayedFlightsReport from "./DelayedFlightsReport";

const initialReport = {
  turnarounds: {
    columns: {},
    items: [],
  },
  cancellations: {
    columns: {},
    items: [],
  },
  paxServices: {
    columns: {},
    items: [],
  },
  paxCancellations: {
    columns: {},
    items: [],
  },
  extraServices: {
    columns: {},
    items: [],
  },
  delayed_summary: {},
  charts: {
    flights: [],
    cancellations: [],
  },
};

const PerformanceReport = ({ admin, dates, airlines, airports }) => {
  const { t } = useTranslation();
  const [report, setReport] = useState(initialReport);
  const turnaroundRef = useRef(null);
  const [chartHeight, setChartHeight] = useState(0);

  useEffect(() => {
    i18n.changeLanguage(admin.language);
  }, []);

  useEffect(() => {
    if (turnaroundRef.current) {
      setChartHeight(turnaroundRef.current.clientHeight);
    }
  }, [report]);

  const getReport = (
    date_from = "",
    date_to = "",
    airport = "",
    airline = ""
  ) => {
    axios
      .get(
        `/admin/customers/performance_report?date_from=${date_from}&date_to=${date_to}&airport=${airport}&airline=${airline}&format=json`
      )
      .then((response) => {
        setReport(response.data.report);
      });
  };

  const sortedChartsData = report.charts.flights.sort((a, b) => {
    const numA = parseInt(a.gate.slice(1), 10);
    const numB = parseInt(b.gate.slice(1), 10);
    if (numA === numB) return a.gate[0].localeCompare(b.gate[0]);
    return numA - numB;
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SearchForm
          locale={t}
          admin={admin}
          setData={getReport}
          getLink={() => {}}
          airports={airports}
          airlines={airlines}
          showAirlines={true}
          date={dates}
          downloadable={false}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Paper elevation={3} style={{ padding: "16px" }} ref={turnaroundRef}>
          <ReportSection
            title="Flight Turnaround Summary"
            table={report.turnarounds}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box
          style={{
            padding: "16px",
            height: chartHeight,
            boxShadow:
              "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
            borderRadius: "4px",
          }}
        >
          <Typography variant="h6" className="report-title">
            Total Flights by Gate
          </Typography>
          {report.charts.flights.length > 0 && (
            <CBarChart data={sortedChartsData} keys={["gate", "total"]} />
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          style={{
            padding: "16px",
            boxShadow:
              "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
            borderRadius: "4px",
          }}
        >
          <Typography variant="h6" className="report-title">
            Delayed and Early Flights Report
          </Typography>
          {Object.keys(report.delayed_summary).length > 0 && (
            <DelayedFlightsReport data={report.delayed_summary} />
          )}
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Paper elevation={3} style={{ padding: "16px" }}>
          <ReportSection
            title="Overview of Ramp Service Cancellations"
            table={report.cancellations}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box
          style={{
            padding: "16px",
            height:
              report.charts.cancellations.length > 0 ? "auto" : chartHeight,
            boxShadow:
              "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
            borderRadius: "4px",
          }}
        >
          <Typography variant="h6" className="report-title">
            Distribution of Flight Cancellations
          </Typography>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CPieChart data={report.charts.cancellations} />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Paper elevation={3} style={{ padding: "16px" }}>
          <Typography variant="h6" className="report-title">
            Passenger Services by Aircraft Type and Operation
          </Typography>
          {report.paxServices.items.length > 0 ? (
            <PassengerServicesChart data={report.paxServices.items} />
          ) : (
            <div style={{ minHeight: 57 }}></div>
          )}
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Paper elevation={3} style={{ padding: "16px" }}>
          <ReportSection
            title="Overview of Passenger Service Cancellations"
            table={report.paxCancellations}
          />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={3} style={{ padding: "16px" }}>
          <Typography variant="h6" className="report-title">
            Detailed Report of Additional Services
          </Typography>
          <ServicesTabs groupedServices={report.extraServices.items} />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default PerformanceReport;
