import React, { useState } from "react";

const HelpPage = ({ articles, topics }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredArticles, setFilteredArticles] = useState([]);

  const handleSearch = () => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const results = articles.filter(
      (article) =>
        article.title.toLowerCase().includes(lowerCaseQuery) ||
        article.tag_list.some((tag) =>
          tag.toLowerCase().includes(lowerCaseQuery)
        )
    );
    setFilteredArticles(results);
  };

  return (
    <div>
      <div className="search-container">
        <input
          type="text"
          placeholder="Search by title or tags..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ width: "300px", marginRight: "10px" }}
        />
        <button onClick={handleSearch}>Search</button>
      </div>

      <div className="help-page-container">
        {searchQuery && filteredArticles.length > 0 ? (
          <div className="search-results">
            <h3>Search Results:</h3>
            <ul>
              {filteredArticles.map((article) => (
                <li key={article.id}>
                  <a href={`/admin/help_pages/${article.id}`}>
                    {article.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          topics.map((topic) => (
            <div key={topic} className="help-topic-card">
              <h3>{topic.charAt(0).toUpperCase() + topic.slice(1)}</h3>
              <ul>
                {topic == "Contact Us" && (
                  <li key={0}>
                    <a href={`/admin/bug_reports/new`}>Submit Bug Report</a>
                  </li>
                )}
                {articles
                  .filter((article) => article.topic === topic)
                  .map((article) => (
                    <li key={article.id}>
                      <a href={`/admin/help_pages/${article.id}`}>
                        {article.title}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default HelpPage;
