import React from "react";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Spreadsheet from "react-spreadsheet";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import { toHoursAndMinutes, formatDateUS } from "../../helpers";

const ScheduledServices = ({ searchDate, services, locale, airports }) => {
  const initialData =
    services.length > 0
      ? services.map((s) => {
          return [
            { value: formatDateUS(s.flight_date), readOnly: true },
            { value: formatDateUS(s.flight_date2), readOnly: true },
            { value: s.flight_in, readOnly: true },
            { value: s.flight_out, readOnly: true },
            { value: s.flight_from, readOnly: true },
            { value: s.flight_dest, readOnly: true },
            { value: s.flight_sta, readOnly: true },
            { value: s.flight_eta, readOnly: true },
            { value: s.flight_std, readOnly: true },
            { value: s.flight_etd, readOnly: true },
            { value: s.flight_gate, readOnly: true },
            { value: s.flight_dgate, readOnly: true },
            {
              value:
                s.crews && s.crews.length
                  ? s.crews.map((c) => c["label"].split(" / ")[0]).join(", ")
                  : [],
              readOnly: true,
            },
            {
              value:
                s.supervisor && s.supervisor.length
                  ? s.supervisor.map((c) => c["value"]).join(", ")
                  : [],
              readOnly: true,
            },
            { value: toHoursAndMinutes(s.time_from), readOnly: true },
            { value: toHoursAndMinutes(s.time_to), readOnly: true },
            { value: toHoursAndMinutes(s.time_dfrom), readOnly: true },
            { value: toHoursAndMinutes(s.time_dto), readOnly: true },
            {
              value:
                s.equipment && s.equipment.length
                  ? s.equipment.map((c) => c["value"]).join(", ")
                  : [],
              readOnly: true,
            },
          ];
        })
      : services;
  return (
    <div>
      <h2 className="dh2">{locale("services")}</h2>
      <Button
        href={`/admin/services/get_all_by_date?date=${searchDate}&airports=${airports}`}
        variant="outlined"
        startIcon={<CloudDownloadIcon />}
      >
        {locale("download")}
      </Button>
      <TableContainer component={Paper} sx={{ mt: 2, mb: 2 }}>
        <Spreadsheet
          data={initialData}
          columnLabels={[
            locale("date_a"),
            locale("date_d"),
            locale("inbound"),
            locale("outbound"),
            locale("from"),
            "Dest",
            "STA",
            "ETA",
            "STD",
            "ETD",
            locale("gate_a"),
            locale("gate_d"),
            locale("crew.other"),
            "Supervisor",
            locale("start_time_a"),
            locale("end_time_a"),
            locale("start_time_d"),
            locale("end_time_d"),
            locale("equipment"),
          ]}
          hideRowIndicators={true}
          className="mt-15"
        />
      </TableContainer>
    </div>
  );
};

export default ScheduledServices;
