import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { green, amber, red, grey } from "@mui/material/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import LocalAirportIcon from "@mui/icons-material/LocalAirport";
import CancelFlightForm from "../Service/CancelFlightForm";
import MessageIcon from "@mui/icons-material/Message";
import Grid from "@mui/material/Grid";
import { formatDateUS } from "../../helpers";
import axios from "axios";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";

const token = document.querySelector('[name="csrf-token"]').content;
axios.defaults.headers.common["X-CSRF-Token"] = token;

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ServiceCard = ({
  service,
  type,
  btnOneClickHandler,
  btnTwoClickHandler,
  btnThreeClickHandler,
  setService,
  displayBtnOne,
  displayBtnTwo,
  displayBtnThree,
  setServices,
  isOnline,
  handleOpen,
  locale,
}) => {
  const rsforms = localStorage.getItem("rsforms");
  const arrival = type === "arrival" ? true : false;
  const delay = arrival
    ? Math.round(service.arrival_delay / 60)
    : Math.round(service.departure_delay / 60);
  let statusColor = green[500];
  if (delay > 15) {
    statusColor = red[500];
  } else if (delay > 0 && delay <= 15) {
    statusColor = amber[500];
  } else {
    statusColor = green[500];
  }

  const thStyle = {
    backgroundColor: statusColor,
    color: "#f5f5f5",
    borderBottom: "1px solid #f5f5f5",
    textTransform: "uppercase",
    fonWeight: "bold",
  };
  const tdStyle = { borderBottom: "1px solid #ededed" };

  const initialCancelFlight = {
    date_notified: null,
    original_date: service.flight_date,
    flight_time: arrival ? service.flight_sta : service.flight_std,
    service_id: service.id,
    flight_number: arrival ? service.flight_in : service.flight_out,
    reason: "",
    notes: "",
  };
  const [expanded, setExpanded] = useState(false);
  const [cancelFlight, setCancelFlight] = useState(initialCancelFlight);
  const [showForm, setShowForm] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useState(() => {
    if (service.rsforms_count == 0 && rsforms != null) {
      if (
        JSON.parse(rsforms)
          .map((f) => f.op201026_form.service_id)
          .includes(service.id)
      ) {
        service.rsforms_count = 1;
      }
    }
  }, []);

  const formBtnClickHandler = () => {
    setService(type, service);
    btnOneClickHandler();
  };

  const reportBtnClickHandler = () => {
    setService(type, service);
    btnTwoClickHandler();
  };

  const csreportBtnClickHandler = () => {
    setService(type, service);
    btnThreeClickHandler();
  };

  const commentBtnClickHandler = () => {
    setService(type, service);
    handleOpen();
  };

  const cancelBtnClickHandler = () => {
    setShowForm(true);
  };

  const submitCancelFlightFormHandler = (data) => {
    axios
      .post("/admin/canceled_flights.json", data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status == 201) {
          setServices((prevState) => {
            const newState = prevState.map((service) => {
              if (service.id === cancelFlight.service_id) {
                if (arrival) {
                  service.cancel_a =
                    data.cancelled_flight.revoked_services.includes("ramp");
                } else {
                  service.cancel_d =
                    data.cancelled_flight.revoked_services.includes("ramp");
                }
                service.cancel_form = true;
              }
              return service;
            });
            return newState;
          });
          setShowForm(false);
          alertify.success(
            locale("success_messages.form_submitted"),
            2,
            () => {}
          );
        }
      })
      .catch((error) => {
        setShowForm(false);
        alertify.error(locale("error_messages.main"), 5);
      });
  };

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card sx={{ maxWidth: 345, my: 2 }}>
        <CardHeader
          avatar={
            <Avatar aria-label="logo">
              <img src={service.logo} alt="logo" width="100%" height="100%" />
            </Avatar>
          }
          action={
            <IconButton aria-label="settings">
              {arrival ? <FlightLandIcon /> : <FlightTakeoffIcon />}
            </IconButton>
          }
          title={arrival ? service.flight_in : service.flight_out}
          subheader={service.airline}
        />
        <CardContent>
          <table>
            <tbody>
              <tr>
                <th style={thStyle}>{locale("date")}</th>
                <td style={tdStyle}>{formatDateUS(service.flight_date)}</td>
              </tr>
              <tr>
                <th style={thStyle}>{arrival ? "STA" : "STD"}</th>
                <td style={tdStyle}>
                  {arrival ? service.flight_sta : service.flight_std}
                </td>
              </tr>
              <tr>
                <th style={thStyle}>{arrival ? "ETA" : "ETD"}</th>
                <td style={tdStyle}>
                  {arrival ? service.flight_eta : service.flight_etd}
                </td>
              </tr>
              <tr>
                <th style={thStyle}>{arrival ? "ATA" : "ATD"}</th>
                <td style={tdStyle}>
                  {arrival ? service.flight_ata : service.flight_atd}
                </td>
              </tr>
              <tr>
                <th style={thStyle}>{locale("delay")}</th>
                <td style={tdStyle}>
                  {delay <= 0 ? "No delay" : `${delay} minutes`}
                </td>
              </tr>
              <tr>
                <th style={thStyle}>{locale("status")}</th>
                <td style={tdStyle}>
                  {arrival ? service.flight_status : service.flight_dstatus}
                </td>
              </tr>
            </tbody>
          </table>
        </CardContent>
        <CardActions disableSpacing>
          {displayBtnOne && (
            <Tooltip title={locale("ramp_checklist")}>
              <span>
                <IconButton
                  disabled={service.flight_ata === "-"}
                  size="large"
                  aria-label="OP201026"
                  onClick={
                    service.rsforms_count > 0 ? null : formBtnClickHandler
                  }
                >
                  <AssignmentTurnedInIcon
                    sx={{
                      color:
                        service.flight_ata !== "-"
                          ? service.rsforms_count > 0
                            ? green[500]
                            : red[500]
                          : grey[500],
                    }}
                  />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {displayBtnTwo && (
            <Tooltip title={locale("ramp_report")}>
              <span>
                <IconButton
                  disabled={arrival ? service.flight_ata === "-" : false}
                  size="large"
                  aria-label="Report"
                  onClick={
                    arrival
                      ? service.report_a
                        ? null
                        : reportBtnClickHandler
                      : service.report_d
                      ? null
                      : reportBtnClickHandler
                  }
                >
                  <LocalAirportIcon
                    sx={{
                      color: arrival
                        ? service.flight_ata !== "-"
                          ? service.report_a
                            ? green[500]
                            : red[500]
                          : grey[500]
                        : service.report_d
                        ? green[500]
                        : red[500],
                    }}
                  />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {displayBtnThree && (
            <Tooltip title={locale("cleaning_report")}>
              <span>
                <IconButton
                  size="large"
                  aria-label="Report"
                  onClick={service.report_cs ? null : csreportBtnClickHandler}
                >
                  <CleaningServicesIcon
                    sx={{ color: service.report_cs ? green[500] : red[500] }}
                  />
                </IconButton>
              </span>
            </Tooltip>
          )}
          <Tooltip
            title={
              service.comment ? locale("edit_comment") : locale("add_comment")
            }
          >
            <span>
              <IconButton size="large" onClick={commentBtnClickHandler}>
                <MessageIcon
                  sx={{ color: service.comment ? green[500] : red[500] }}
                />
              </IconButton>
            </span>
          </Tooltip>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="Show More"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>{locale("crew.other")}: </Typography>
            <div className="cpanel top">
              <table className="dataTable">
                <tbody>
                  {service.crews.map((crew) => {
                    return (
                      <tr key={crew["value"]}>
                        <th>{crew["label"]}</th>
                        <td>
                          {service.crew_members[crew["value"]]?.join(", ")}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <Typography paragraph>{locale("equipment")}: </Typography>
            <Box sx={{ mb: "10px" }}>
              {service.equipment.map((el) => {
                return (
                  <Chip
                    label={el["label"]}
                    color="default"
                    key={el["value"]}
                    sx={{ m: "2px" }}
                  />
                );
              })}
            </Box>
            <Box sx={{ mb: 1, mt: 4 }}>
              {!service.cancel_form && (
                <Button
                  fullWidth
                  onClick={cancelBtnClickHandler}
                  color="error"
                  variant="contained"
                  disabled={!isOnline}
                >
                  {isOnline ? locale("cancel") : locale("no_internet")}
                </Button>
              )}
            </Box>
          </CardContent>
        </Collapse>
      </Card>
      {showForm && (
        <CancelFlightForm
          showForm={showForm}
          cancelFlight={cancelFlight}
          setCancelFlight={setCancelFlight}
          onClickClose={setShowForm}
          initialCancelFlight={initialCancelFlight}
          createRecord={submitCancelFlightFormHandler}
          t={locale}
        />
      )}
    </Grid>
  );
};

export default ServiceCard;
