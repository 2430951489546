import React from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const ReportSection = ({ title, table }) => {
  return (
    <div className="report-section">
      <Typography variant="h6" className="report-title">
        {title}
      </Typography>
      <TableContainer component={Paper} sx={{ borderRadius: 0 }}>
        <Table style={{ marginBottom: 0 }}>
          <TableHead>
            {table.items.length > 0 && (
              <TableRow>
                {Object.keys(table.columns).map((key, index) => (
                  <TableCell key={index} style={{ backgroundColor: "#f2f2f2" }}>
                    {table.columns[key]}
                  </TableCell>
                ))}
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {table.items.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={Object.keys(table.columns).length || 1}
                  align="center"
                  style={{ padding: "16px" }}
                >
                  <Typography>
                    No data available for the selected date range and filters.
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              table.items.map((item, rowIndex) => (
                <TableRow key={rowIndex}>
                  {Object.keys(table.columns).map((key, colIndex) => (
                    <TableCell key={colIndex}>{item[key]}</TableCell>
                  ))}
                </TableRow>
              ))
            )}

            {title == "Flight Turnaround Summary" && table.items.length > 0 && (
              <TableRow>
                <TableCell key={"total_1"}>Total: </TableCell>
                <TableCell key={"total_2"}>
                  {table.items.reduce(
                    (accumulator, currentValue) =>
                      accumulator + currentValue.total,
                    0
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ReportSection;
