import React, { useEffect, useState } from "react";
import ModalDialog from "../ui/ModalDialog";
import {
  ButtonGroup,
  Button,
  Grid,
  FormHelperText,
  InputLabel,
  Input,
} from "@mui/material";
import CustomSelect from "../ui/CustomSelect";
import { customStyles } from "../../styles";
import { formatTimeEastern, formatDateInt } from "../../helpers";
import axios from "axios";
import alertify from "alertifyjs";

const token = document.querySelector('[name="csrf-token"]').content;
axios.defaults.headers.common["X-CSRF-Token"] = token;
alertify.set("notifier", "position", "top-right");
alertify.set("notifier", "delay", 5);

const AddFlightForm = ({
  showForm,
  onClick,
  date,
  flights,
  airport,
  setRunTableUpdate,
  locale,
}) => {
  const options = [
    { label: locale("arrival"), value: "Arrival" },
    { label: locale("departure"), value: "Departure" },
  ];

  const flightTypeOptions = [
    { label: locale("diverted"), value: 1 },
    { label: locale("charter"), value: 2 },
    { label: "Ferry In / Live Out", value: 3 },
    { label: "Live In / Ferry Out", value: 4 },
  ];

  const [airlines, setAirlines] = useState([]);
  const [airline, setAirline] = useState({ value: "", label: "", logo: "" });
  const [adType, setAdType] = useState({ label: "", value: "" });
  const [flightType, setFlightType] = useState({ label: "", value: "" });
  const [flightNumber, setFlightNumber] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [nextStep, setNextStep] = useState(false);
  const [response, setResponse] = useState({});
  const [addFlightFormErrors, setAddFlightFormErrors] = useState({
    airline: { hasError: false, helperText: "" },
    adType: { hasError: false, helperText: "" },
    flightNumber: { hasError: false, helperText: "" },
    flightType: { hasError: false, helperText: "" },
  });

  useEffect(() => {
    if (
      airline !== null &&
      airline.value !== "" &&
      adType !== null &&
      adType.value !== "" &&
      flightType !== null &&
      flightType.value !== "" &&
      flightNumber &&
      validateFlightNumber(flightNumber)
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [
    flightNumber,
    setFlightNumber,
    adType,
    setAdType,
    flightType,
    setFlightType,
    airline,
    setAirline,
  ]);

  useEffect(() => {
    fetch(
      "https://raw.githubusercontent.com/dotmarn/Airlines/master/airlines.json"
    )
      .then((response) => response.json())
      .then((response) => {
        setAirlines(
          response
            .map((a) => {
              return { label: a["name"], value: a["name"], logo: a["logo"] };
            })
            .sort((a, b) => {
              if (a.value < b.value) {
                return -1;
              } else if (a.value > b.value) {
                return 1;
              }
              return 0;
            })
        );
      });
    customStyles.content.maxWidth = "400px";
    customStyles.content.height = "75vh";
  }, []);

  const validateFlightNumber = (value) => {
    const regex = /^[a-zA-Z]{2,3}\d{2,4}$/;
    let correctFlightNumber = value.match(regex);
    let result = {};

    result = correctFlightNumber
      ? { flightNumber: { hasError: false, helperText: "" } }
      : {
          flightNumber: {
            hasError: true,
            helperText: locale("error_messages.flight_number"),
          },
        };

    if (correctFlightNumber) {
      Object.values(flights).forEach((flight) => {
        if (
          flight.flight_number1 === value ||
          flight.flight_number2 === value
        ) {
          result = {
            flightNumber: {
              hasError: true,
              helperText: locale("error_messages.flight_listed"),
            },
          };
        }
      });
    }

    setAddFlightFormErrors((prevState) => {
      return { ...prevState, ...result };
    });

    return !result.flightNumber.hasError;
  };

  const closeModalHadler = () => {
    customStyles.content.height = "";
    onClick(false);
  };

  const flightChangeHandler = (e) => {
    const { value } = e.target;
    setFlightNumber(value.toUpperCase());
    validateFlightNumber(value);
  };

  const selectAirlineHandler = (option) => {
    if (option === null) {
      setAddFlightFormErrors((prevState) => {
        return {
          ...prevState,
          airline: {
            hasError: true,
            helperText: locale("error_messages.select_option"),
          },
        };
      });
    } else {
      setAddFlightFormErrors((prevState) => {
        return {
          ...prevState,
          airline: {
            hasError: false,
            helperText: "",
          },
        };
      });
    }
    setAirline(option);
  };

  const selectAdTypeHandler = (option) => {
    if (option === null) {
      setAddFlightFormErrors((prevState) => {
        return {
          ...prevState,
          adType: {
            hasError: true,
            helperText: locale("error_messages.select_option"),
          },
        };
      });
    } else {
      setAddFlightFormErrors((prevState) => {
        return {
          ...prevState,
          adType: {
            hasError: false,
            helperText: "",
          },
        };
      });
    }
    setAdType(option);
  };

  const selectFlightTypeHandler = (option) => {
    if (option === null) {
      setAddFlightFormErrors((prevState) => {
        return {
          ...prevState,
          flightType: {
            hasError: true,
            helperText: locale("error_messages.select_option"),
          },
        };
      });
    } else {
      setAddFlightFormErrors((prevState) => {
        return {
          ...prevState,
          flightType: {
            hasError: false,
            helperText: "",
          },
        };
      });
    }
    setFlightType(option);
  };

  const checkAndSubmitDataHandler = async () => {
    const [year, month, day] = date.split("-");
    const oneDay = new Date().getTime() + 1 * 24 * 60 * 60 * 1000;
    const searchDay = Date.parse(date);
    const type = isArrival ? "destination" : "origin";
    const chars = flightNumber.slice(0, flightNumber.search(/\d/));
    const numbs = flightNumber.replace(chars, "");
    const url =
      searchDay <= oneDay
        ? `/admin/flights/schedules?date=${date}&type=${type}&airport=${airport.iata}&airline=${chars}&flight_number=${numbs}`
        : `/admin/flights/history?flight=${flightNumber}&date=${year}-${month}-${day}&format=json`;
    const response = await fetch(url);
    const json = await response.json();
    const notEmpty = json?.scheduled?.length > 0;

    const flightObject = notEmpty ? json?.scheduled[0] : {};
    setResponse(flightObject);
    setDisabled(!notEmpty);
    setNextStep(true);
  };

  const submitFormHandler = async () => {
    const data = {
      flight: {
        airline: airline.value,
        logo: airline.logo,
        airport_id: airport.id,
      },
      registry_line: {
        ad_type: adType.value,
        flight_type: flightType.value,
        flight_number: flightNumber,
      },
      date,
    };
    if (adType.value === "Arrival") {
      data.flight.inbound = flightNumber;
    } else {
      data.flight.outbound = flightNumber;
    }

    try {
      const response = await axios.post(
        `/admin/registry_lines/add_flight.json`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.status === 200) {
        onClick(false);
        let notification = alertify.success(response.data.message);
        notification.ondismiss = function () {
          setRunTableUpdate(1);
        };
      } else {
        alertify.success(response.data.error);
      }
    } catch (error) {
      alertify.error(error.message);
    }
  };

  const isArrival = adType.value === "Arrival";

  return (
    <ModalDialog
      showForm={showForm}
      contentLabel={"Add Flight Modal"}
      title={locale("add_flight")}
      closeClicked={closeModalHadler}
    >
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {!nextStep && (
          <>
            <Grid item xs={12}>
              <InputLabel
                required
                sx={{ mb: 1 }}
                style={{ textAlign: "left" }}
                htmlFor="airline"
                error={addFlightFormErrors.airline.hasError}
              >
                {locale("airline")}
              </InputLabel>

              <CustomSelect
                isClearable={true}
                isSearchable={true}
                options={airlines}
                id="airline"
                name="airline"
                isValid={true}
                value={airline}
                onChange={selectAirlineHandler}
              />
              <FormHelperText error={addFlightFormErrors.airline.hasError}>
                {addFlightFormErrors.airline.helperText}
              </FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <InputLabel
                required
                sx={{ mb: 1, mt: 1 }}
                style={{ textAlign: "left" }}
                htmlFor="ad_type"
                error={addFlightFormErrors.adType.hasError}
              >
                {locale("ad_type")}
              </InputLabel>

              <CustomSelect
                isClearable={true}
                isSearchable={true}
                options={options}
                id="ad_type"
                name="ad_type"
                isValid={true}
                value={adType}
                onChange={selectAdTypeHandler}
              />
              <FormHelperText error={addFlightFormErrors.adType.hasError}>
                {addFlightFormErrors.adType.helperText}
              </FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <InputLabel
                required
                sx={{ mb: 1, mt: 1 }}
                style={{ textAlign: "left" }}
                htmlFor="flight_type"
                error={addFlightFormErrors.flightNumber.hasError}
              >
                {locale("flight_type")}
              </InputLabel>
              <CustomSelect
                isClearable={true}
                isSearchable={true}
                options={flightTypeOptions}
                id="flight_type"
                name="flight_type"
                isValid={true}
                value={flightType}
                onChange={selectFlightTypeHandler}
              />
              <FormHelperText error={addFlightFormErrors.flightType.hasError}>
                {addFlightFormErrors.flightType.helperText}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} sx={{ mb: 3 }}>
              <InputLabel
                required
                sx={{ mb: 1, mt: 1 }}
                style={{ textAlign: "left" }}
                htmlFor="flight_number"
                error={addFlightFormErrors.flightNumber.hasError}
              >
                {locale("flight_number")}
              </InputLabel>
              <Input
                placeholder={locale("flight_number")}
                id="flight_number"
                name="flight_number"
                type="text"
                value={flightNumber}
                onChange={flightChangeHandler}
                sx={{ width: "100%" }}
              />
              <FormHelperText error={addFlightFormErrors.flightNumber.hasError}>
                {addFlightFormErrors.flightNumber.helperText}
              </FormHelperText>
            </Grid>
          </>
        )}
        {/* Next Step will display flight details */}
        {nextStep && (
          <>
            <Grid item xs={2}>
              <img src={airline.logo} />
            </Grid>
            <Grid item xs={10}>
              <div
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                <p
                  style={{
                    color: "#000",
                    textTransform: "uppercase",
                    marginBottom: "10px",
                    textAlign: "center",
                  }}
                >
                  {airline.value}
                </p>
                <small
                  style={{
                    color: "#5B7997",
                    display: "block",
                    marginBottom: "15px",
                    textAlign: "center",
                  }}
                >
                  {Object.keys(response).length > 0
                    ? `${response?.ident_icao} / ${response?.ident_iata}`
                    : flightNumber}
                </small>
              </div>
            </Grid>
            {Object.keys(response).length > 0 && (
              <>
                <Grid item xs={6} sx={{ mb: 2 }}>
                  <div id="leftColumn">
                    <p style={{ color: "#000" }}>
                      {isArrival
                        ? locale("destination").toUpperCase()
                        : locale("origin").toUpperCase()}
                    </p>
                    <p style={{ marginTop: 0 }}>
                      {isArrival
                        ? response?.destination_iata
                        : response?.origin_iata}
                    </p>
                    <p style={{ color: "green", marginTop: 0 }}>
                      {locale("aircraft_type")} {response?.aircraft_type}
                    </p>
                    <p style={{ marginTop: 0, marginBottom: 0 }}>
                      {formatDateInt(
                        isArrival
                          ? response?.scheduled_in
                          : response?.scheduled_out
                      )}
                    </p>
                    <small>
                      {formatTimeEastern(
                        isArrival
                          ? response?.scheduled_in
                          : response?.scheduled_out
                      )}
                    </small>
                  </div>
                </Grid>
                <Grid item xs={6} sx={{ mb: 2 }}>
                  <div id="rightColumn">
                    <p>
                      {isArrival
                        ? locale("destination").toUpperCase()
                        : locale("origin").toUpperCase()}
                    </p>
                    <p style={{ marginTop: 0 }}>
                      {isArrival
                        ? response?.origin_iata
                        : response?.destination_iata}
                    </p>
                    <p style={{ color: "green", marginTop: 0 }}>
                      {locale("aircraft_type")} {response?.aircraft_type}
                    </p>
                    <p style={{ marginTop: 0, marginBottom: 0 }}>
                      {formatDateInt(
                        isArrival
                          ? response?.scheduled_out
                          : response?.scheduled_in
                      )}
                    </p>
                    <small>
                      {formatTimeEastern(
                        isArrival
                          ? response?.scheduled_out
                          : response?.scheduled_in
                      )}
                    </small>
                  </div>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              {Object.keys(response).length === 0 && (
                <div
                  style={{
                    minHeight: "190px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p style={{ textAlign: "center" }}>{locale("no_data")}</p>
                </div>
              )}
            </Grid>
          </>
        )}
        <ButtonGroup
          variant="contained"
          aria-label="button group"
          fullWidth={true}
          sx={{ ml: 2 }}
        >
          {nextStep && (
            <Button color="error" onClick={() => setNextStep(false)}>
              {locale("back")}
            </Button>
          )}
          <Button
            color="primary"
            variant="contained"
            disabled={disabled}
            onClick={nextStep ? submitFormHandler : checkAndSubmitDataHandler}
          >
            {nextStep ? locale("submit") : locale("next")}
          </Button>
        </ButtonGroup>
      </Grid>
    </ModalDialog>
  );
};

export default AddFlightForm;
