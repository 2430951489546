import React, { useState } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import ReportSection from "./ReportSection";
import { formatServiceDataForReport } from "../../helpers";

const ServicesTabs = ({ groupedServices }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const serviceNames = Object.keys(groupedServices);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      {/* Tabs */}
      <Tabs value={selectedTab} onChange={handleTabChange} centered>
        {serviceNames.map((name, index) => (
          <Tab key={index} label={name} />
        ))}
      </Tabs>

      {/* Tab Panels */}
      {serviceNames.map((name, index) => (
        <div role="tabpanel" hidden={selectedTab !== index} key={index}>
          {selectedTab === index && (
            <Box sx={{ p: 3 }}>
              <ReportSection
                title={name}
                table={formatServiceDataForReport(groupedServices[name])}
              />
            </Box>
          )}
        </div>
      ))}
    </Box>
  );
};

export default ServicesTabs;
