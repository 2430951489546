import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Button } from "@mui/material";
import { red } from "@mui/material/colors";
import CancelFlightForm from "./Service/CancelFlightForm";
import axios from "axios";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    error: {
      light: red[300],
      main: red[500],
      dark: red[700],
      darker: red[900],
    },
  },
});

const token = document.querySelector('[name="csrf-token"]').content;
axios.defaults.headers.common["X-CSRF-Token"] = token;

export default function WeeklyFlightSchedule({ rows, setRows, locale, date }) {
  const initialCancelFlight = {
    date_notified: null,
    original_date: date,
    flight_time: "",
    service_id: null,
    flight_number: "",
    reason: "",
    notes: "",
  };

  const columns = [
    {
      id: "flight_number1",
      label: `${locale("flight").toUpperCase()} #`,
      minWidth: 100,
      align: "center",
      backgroundColor: "#92cddc",
    },
    {
      id: "city1",
      label: locale("city").toUpperCase(),
      minWidth: 100,
      align: "center",
      backgroundColor: "#92cddc",
    },
    {
      id: "scheduled_time1",
      label: locale("sta").toUpperCase(),
      minWidth: 100,
      align: "center",
      backgroundColor: "#92cddc",
    },
    {
      id: "ac_type1",
      label: locale("ac_type").toUpperCase(),
      minWidth: 100,
      align: "center",
      backgroundColor: "#92cddc",
    },
    {
      id: "flight_number2",
      label: `${locale("flight").toUpperCase()} #`,
      minWidth: 100,
      align: "center",
      backgroundColor: "#CCC0DA",
    },
    {
      id: "city2",
      label: locale("city").toUpperCase(),
      minWidth: 100,
      align: "center",
      backgroundColor: "#CCC0DA",
    },
    {
      id: "scheduled_time2",
      label: locale("sta").toUpperCase(),
      minWidth: 100,
      align: "center",
      backgroundColor: "#CCC0DA",
    },
    {
      id: "ac_type2",
      label: locale("ac_type").toUpperCase(),
      minWidth: 100,
      align: "center",
      backgroundColor: "#CCC0DA",
    },
    {
      id: "status",
      label: locale("status").toUpperCase(),
      minWidth: 100,
      align: "center",
      backgroundColor: "#ffffff",
    },
    {
      id: "supervisor",
      label: "SUPERVISOR",
      minWidth: 100,
      align: "center",
      backgroundColor: "#ffffff",
    },
    {
      id: "crew",
      label: locale("crew.one").toUpperCase(),
      minWidth: 100,
      align: "center",
      backgroundColor: "#ffffff",
    },
    {
      id: "ad_type",
      label: locale("arr_dep").toUpperCase(),
      minWidth: 100,
      align: "center",
      format: (value) => value.toUpperCase(),
      backgroundColor: "#ffffff",
    },
    {
      id: "actions",
      label: locale("actions").toUpperCase(),
      minWidth: 100,
      align: "center",
      backgroundColor: "#ffffff",
    },
  ];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [cancelFlight, setCancelFlight] = useState(initialCancelFlight);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const cancelBtnClickHandler = (row) => {
    setCancelFlight((prevState) => {
      return {
        ...prevState,
        flight_number:
          row.ad_type === "Departure" ? row.flight_number2 : row.flight_number1,
        flight_time:
          row.ad_type === "Departure"
            ? row.scheduled_time2
            : row.scheduled_time1,
        registry_line_id: row.id,
      };
    });
    setShowModal(true);
  };

  const submitFormHandler = (newFormData) => {
    axios
      .post("/admin/canceled_flights/create_record.json", newFormData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status == 200) {
          setRows((prevState) => {
            const newState = prevState.map((row) => {
              if (row.id === newFormData.cancelled_flight.registry_line_id) {
                row.cancel_form = true;
                row.color = "#f44336";
              }
              return row;
            });
            return newState;
          });
          setShowModal(false);
          alertify.success(
            locale("success_messages.form_submitted"),
            2,
            () => {}
          );
        }
      })
      .catch((error) => {
        setShowModal(false);
        alertify.error(locale("error_messages.main"), 5);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Paper sx={{ width: "100%", pt: "20px", pb: "20px" }}>
        <TableContainer sx={{ maxHeight: 650 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={4}
                  style={{ fontWeight: "bold", backgroundColor: "#e4eaec" }}
                >
                  {locale("inbound").toUpperCase()}
                </TableCell>
                <TableCell
                  align="center"
                  colSpan={9}
                  style={{ fontWeight: "bold", backgroundColor: "#e4eaec" }}
                >
                  {locale("outbound").toUpperCase()}
                </TableCell>
              </TableRow>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      top: 57,
                      minWidth: column.minWidth,
                      fontWeight: "bold",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              backgroundColor:
                                column.id !== "crew"
                                  ? row[column.id]
                                    ? column.backgroundColor
                                    : "#ffffff"
                                  : row["color"],
                            }}
                          >
                            {column.id === "status" &&
                              (row.cancelled ? (
                                <span style={{ color: red[500] }}>
                                  {locale("canceled").toUpperCase()}
                                </span>
                              ) : (
                                <span>{locale("scheduled").toUpperCase()}</span>
                              ))}
                            {column.id === "ad_type" &&
                              column.format(locale(value.toLowerCase()))}
                            {column.id === "actions" && !row.cancel_form && (
                              <Button
                                variant="contained"
                                size="small"
                                sx={{
                                  borderRadius: "20px",
                                }}
                                color="error"
                                onClick={cancelBtnClickHandler.bind(this, row)}
                              >
                                {locale("cancel")}
                              </Button>
                            )}
                            {!["ad_type", "actions", "status"].includes(
                              column.id
                            ) && value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100, 150, 200]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {showModal && (
        <CancelFlightForm
          showForm={showModal}
          cancelFlight={cancelFlight}
          setCancelFlight={setCancelFlight}
          onClickClose={setShowModal}
          initialCancelFlight={initialCancelFlight}
          createRecord={submitFormHandler}
          t={locale}
        />
      )}
    </ThemeProvider>
  );
}
